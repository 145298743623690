<template>
  <div>
    <div class="page-content">
      <div class="max-container">
        <el-row>
          <el-col :span="24">
            <u-animate
                name="bounceInDown"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <h2>新一代AR读物</h2>
            </u-animate>
            <u-animate
                name="bounceInUp"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <img class="draw-cover" src="@/assets/img/mobile/draw1.png" />
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon-blue.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(9)" />
      <img class="city-bg" src="@/assets/img/mobile/city-bg.svg" />
    </div>
  </div>
</template>

<script>
import bg2 from '@/assets/img/bg2.jpg'
export default {
  name: "TeamMobilePage",
  data() {
    return {
      swiper: null,
      bg2
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.page-content {
  background: linear-gradient(0deg, #FFF1EB 0%, #ACE0F9 100%);
  position: relative;
  padding-top: 48px;
  min-height: calc(100vh - 48px);
  height: 100%;
  overflow: hidden;

  .arrow-icon {
    position: absolute;
    display: block;
    bottom: 3em;
    left: 0;
    right: 0;
    height: 64px;
    margin: 0 auto;
    cursor: pointer;
  }

  .max-container {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  h2 {
    position: relative;
    margin: 0 -20px;
    margin-top: 1.4em;
    margin-bottom: 1em;
    font-family: 'Gantari';
    font-size: 3.6em;
    letter-spacing: 0.1em;
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #FBFBFB;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-stroke: 0.6px #FBFBFB;
    text-align: center;
    display: flex;
    justify-content: center;

    &:before {
      position: absolute;
      bottom: -16px;
      content: '新一代AR读物';
      font-family: 'Gantari';
      font-size: 1.05em;
      letter-spacing: 0.1em;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff00;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: 0.6px #FBFBFB;
      text-align: center;
    }
  }

  .draw-cover {
    margin: 0 -20px;
    width: calc(100% + 40px);
  }

  .city-bg {
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    height: calc(100vh + 40px);
    z-index: -1;
  }

}


</style>