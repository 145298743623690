<template>
  <div>
    <div class="page-content">
      <u-animate
          name="fadeIn"
          delay="0s"
          duration="2s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
      >
        <div class="page-bg" :style="{backgroundImage: `url(${bg})`}"></div>
      </u-animate>
      <div class="max-container">
        <el-row type="flex" justify="center">
          <el-col :span="20">
            <u-animate
                name="slideInUp"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <div class="project">
                <div class="project-about">
                  <div class="project-info">
                    <b>模型内容</b>
                    利用AR技术，将《三体》小说文字中的场景提取到现实中来。用户可以通过应用程序来操作模型，体验文字小说无法实现的沉浸式互动，让用户有种身处其中的临场感。
                  </div>
                  <div class="project-info">
                    例如，用户可以通过操作控制面板体验小说中叶文洁向外太空发射信号的剧情桥段。模型内容主要还原了《三体》小说内以宏观叙事和奇思妙想所带来的大型场景，包括其中的建筑、人物和相应动画效果。
                  </div>
                </div>
              </div>
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon-blue.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(20)" />
    </div>
  </div>
</template>

<script>
import bg from '@/assets/img/mobile/threebody-bg4.jpg'
export default {
  name: "ThreeBodyHero",
  data() {
    return {
      swiper: null,
      bg
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .page-bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
    background-color: #000000;
  }

  .page-content {
    position: relative;
    padding-top: 48px;
    min-height: calc(100vh - 48px);
    height: 100%;
    overflow: hidden;

    .arrow-icon {
      position: absolute;
      display: block;
      bottom: 3em;
      left: 0;
      right: 0;
      height: 64px;
      margin: 0 auto;
      cursor: pointer;
    }

    .max-container {
      padding: 0 20px;
      width: calc(100% - 40px);
    }


    .project {
      display: flex;
      height: 100%;
      align-items: center;

      .project-about {
        margin-top: 2em;
        .project-about-title {
          margin-top: 3em;
          display: flex;
          align-items: flex-end;

          .number {
            font-family: 'Dynalight';
            font-style: normal;
            font-weight: 400;
            font-size: 8.6em;
            line-height: .6em;
          }

          .title-wrapper {
            margin-left: 6px;
            margin-bottom: 6px;

            .title {
              font-style: normal;
              font-weight: 700;
              font-size: 26px;
              line-height: 32px;
              letter-spacing: 0.165em;
            }

            .subtitle {
              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 16px;
            }
          }
        }

        .project-info {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 220%;
          text-align: justify;
          letter-spacing: 0.175em;
          margin-top: 2em;

          b {
            margin: .5em 0;
            display: block;
            font-size: 1.4em;
          }
        }
      }
    }

    .starman-ar-icon {
      margin-top: 2em;
      margin-left: 1em;
      height: 64px;
    }

    .starman-title-bg {
      position: absolute;
      text-align: left;
      top: 20%;
      left: 20px;
      font-style: normal;
      font-weight: 900;
      font-size: 6.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      opacity: 1;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: 1px #FFFFFF;
      user-select: none;
      z-index: -1;
    }

    .threebody-title-bg {
      position: absolute;
      text-align: left;
      bottom: 18%;
      left: -40px;
      font-style: normal;
      font-weight: 900;
      font-size: 8.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      opacity: 1;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: .5px #FFFFFF;
      user-select: none;
      z-index: -1;
    }

    .star-icon1 {
      position: absolute;
      bottom: 24%;
      right: 0;
      width: 164px;
    }

    .logo-icon {
      position: absolute;
      bottom: 4em;
      right: 2em;
      width: 60px;
      height: 60px;
    }

    .city-bg {
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      width: calc(100% + 40px);
      height: calc(100vh + 40px);
      z-index: -1;
    }

  }
</style>