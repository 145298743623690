<template>
  <div class="footer">
    <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
    <div class="max-container">
      <el-row>
        <el-col :span="24">
          <p>
            © 2020-2022 星徒（上海）科技发展有限公司<br />
            <a href="https://beian.miit.gov.cn/">沪ICP备2021025443号-1</a>  <img src="@/assets/img/beian-icon.png"/> <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007132">沪公网安备 31010102007132号</a><br />
            商务合作: <a href="mailto:bd@starman.ltd">bd@starman.ltd</a> | 招聘相关: <a href="mailto:hr@starman.ltd">hr@starman.ltd</a><br />
          </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',

}
</script>

<style lang="scss" scoped>
  .footer {
    position: relative;
    padding: 36px 0;
    height: 240px;
    text-align: center;
    background-color: #191919;
    font-size: 14px;

    .logo-smoke {
      position: absolute;
      margin: 0 auto;
      opacity: .3;
      left: 0;
      right: 0;
      height: 230px;
    }

    p {
      color: #878787;
      transform: translateY(220%);

      a {
        color: #FFFFFF;
        text-decoration: none;
      }

      img {
        margin-left: 16px;
        vertical-align: middle;
      }
    }
  }
</style>