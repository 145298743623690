<template>
  <div>
    <div class="page-bg" :style="{backgroundImage: `url(${bg2})`}"></div>
    <div class="page-content">
      <div class="max-container">
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <transition name="fade-up-50">
              <div v-if="$parent.index === 7">
                <h1>+加入我们
                  <span>STAR<br/>MAN</span>
                </h1>
                <div class="info-text">击破现实和虚拟的界限，在强大远景的推动下，我们组成了年轻，果敢，富有协同和探索精神，且拥有自主知识产权、高水平研发、产品和美术团队。</div>

                <div class="joinus-wrapper">
                  <div class="title">招聘岗位</div>
                  <el-row :gutter="40">
                    <el-col :span="8">
                      <div class="joinus-list-title">开发部：</div>
                      <div class="joinus-list-item">人工智能工程师</div>
                      <div class="joinus-list-item">后端开发工程师</div>
                      <div class="joinus-list-item">计算机视觉工程师</div>
                      <div class="joinus-list-item">WEB前端工程师</div>
                      <div class="joinus-list-item">Unity3D工程师</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="joinus-list-title">美术部：</div>
                      <div class="joinus-list-item">三维角色/场景设计师</div>
                      <div class="joinus-list-item">平面设计</div>
                      <div class="joinus-list-item">Unity3D美术工程师</div>
                      <div class="joinus-list-item">Unity3D技术美术</div>
                      <div class="joinus-list-item">GUI界面美术</div>
                    </el-col>
                    <el-col :span="8">
                      <div class="joinus-list-title">产品部：</div>
                      <div class="joinus-list-item">产品经理</div>
                      <div class="joinus-list-item">UX交互设计</div>

                      <a href="/career" target="_blank">
                        <img class="joinus-button" src="@/assets/img/joinus-button.svg" />
                      </a>
                    </el-col>
                  </el-row>
                  <!-- <div class="joinus-link">
                    <a href="/jobs" target="_blank">https://job.starmantech.com</a>
                  </div> -->
                </div>
              </div>
            </transition>
          </el-col>
          <el-col :span="12">
            <transition name="fade-up-50">
              <div v-if="$parent.index === 7">
                <div class="joinus-right-side">
                  <img class="joinus-icon" src="@/assets/img/joinus.png" />

                  <div class="joinus-contect">
                    <img class="joinus-wechat" src="@/assets/img/joinus-wechat.png" />
                    <p>Wechat：cz2128delta</p>
                    <p>E-mail：hr@starman.ltd</p>
                  </div>
                </div>
              </div>
            </transition>
          </el-col>
        </el-row>
      </div>

      <div class="next-icon">
        <i class="el-icon-arrow-down" @click="toFooter" />
      </div>
    </div>
  </div>
</template>

<script>
import bg2 from '@/assets/img/joinus.jpg'
export default {
    name: "AboutUsPage",
    data() {
        return {
          swiper: null,
          bg2
        };
    },
    mounted() {
    },
    methods: {
      toFooter() {
        window.scrollTo(0, 312)
      }
    },
};
</script>
<style scoped lang="scss">
  .page-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
    background-color: #000000;
  }

  .time-text {
    position: absolute;
    right: 0;
    top: calc((100vh - 30px) / 2);
    bottom: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    letter-spacing: 0.175em;
    color: #FBFBFB;
    transform: rotate(-90deg);
    z-index: 2;
  }

  .joinus-right-side {
    text-align: right;

    .joinus-icon {
      width: 15em;
    }

    .joinus-contect {
      position: relative;
      padding-top: 40%;
      text-align: right;

      .joinus-wechat {
        width: 150px;
        height: 150px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 12px 0;
      }
    }
  }

  .page-content {
    display: flex;
    align-items: center;
    height: 100vh;

    .max-container {
      max-width: 1550px;
    }

    .next-icon {
      position: absolute;
      margin: 0 auto;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      color: #FFFFFF;
      font-size: 23px;
      cursor: pointer;
      transition: all linear .1s;

      &:hover {
        transform: translateY(-3px);
      }
    }

    .logo-white {
      position: absolute;
      height: 50px;
      bottom: 50px;
      left: 40px;
    }

    .logo-icon {
      position: absolute;
      width: 80px;
      bottom: 60px;
      right: 40px;
    }

    .next-icon {
      position: absolute;
      margin: 0 auto;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      color: #FFFFFF;
      font-size: 23px;
      cursor: pointer;
      transition: all linear .1s;

      &:hover {
        transform: translateY(-3px);
      }
    }

    h1 {
      margin: 0;
      margin-left: -1em;
      padding-left: 1em;
      font-weight: 900;
      font-size: 8em;
      line-height: 198px;
      letter-spacing: 0.055em;
      color: #FFFFFF;

      span {
        position: absolute;
        left: 0;
        top: -25%;
        font-style: normal;
        font-weight: 900;
        font-size: 1.25em;
        line-height: 1em;
        letter-spacing: 0.2em;
        background: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-text-stroke: 0.6px #FBFBFB;
      }

      b {
        position: absolute;
        padding-left: 8em;
        left: 0;
        font-size: .25em;
        bottom: -5em;
      }
    }

    .info-text {
      width: 70%;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 220%;
      letter-spacing: 0.175em;
    }

    .joinus-wrapper {
      padding: 60px 0;

      .title {
        margin-bottom: 1em;
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 122%;
        color: #FFFFFF;
      }

      .joinus-list-title {
        margin-bottom: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 28px;
        color: #ffffff;
        opacity: .6;
      }

      .joinus-list-item {
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 34px;
        color: #FFFFFF;
      }

      .joinus-button {
        margin-top: 18px;
        width: 140px;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
      }

      .joinus-link {
        margin-top: 1.3em;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 183%;

        a {
          color: #FFFFFF;
          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }
</style>