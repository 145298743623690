<template>
  <div>
    <div class="page-content">
      <u-animate
          name="fadeIn"
          delay="0s"
          duration="2s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
      >
        <div class="page-bg" :style="{backgroundImage: `url(${bg})`}"></div>
      </u-animate>
      <div class="max-container">
        <el-row type="flex" justify="center">
          <el-col :span="20">
            <u-animate
                name="slideInUp"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <div class="project">
                <div class="project-about">
                  <div class="project-info">
                    <b>文字内容</b>
                    <p>第一章节为三体游戏（上），包含了原著中汪淼以“海人”的身份前两次登录三体游戏所遇见的场景。这个章节主要以汪淼的视角通过三体游戏认识到三体人所处的乱纪元以及他们脱水的能力，从而引出纣王需要人推演预测恒纪元与乱纪元的规律。</p>
                    <p>第二章节为三体游戏（中），包含了汪淼以“哥白尼”的身份第三以及第四次登录三体游戏的场景。汪淼揭露了宇宙的基本结构：即之前出现的飞星以及太阳为同一星体，从而推动三体游戏进入第二阶段，第四次则见到了人列计算机。</p>
                    <p>第三章节为三体游戏（下），包含了汪淼第五次以及第六次进入三体游戏。三体星在汪淼错过的时间内经历了大撕裂，三体游戏的目标从预测恒纪元与乱纪元的规律转变为星际移民。汪淼与其他玩家最后见证了三体舰队的出征，随后三体游戏结束。</p>
                    <p>第四章节以地球上发生的故事为主，包括了魏成向汪淼一行演算三体问题的剧情，红岸基地当年的故事（DEMO演示内容）以及叶文洁重回红岸基地和审判日号成为第二红岸基地后被古筝行动破坏。</p>
                    <p>第五章节则将视角转移到了三体星，主要包括了当年接收到红岸基地发来信号的1379监听站和智子三次展开。解释了宇宙中其他文明寻找信号源的原理以及初步展示宇宙中各个文明之间的看法和道德观，为后面引出黑暗森林法则铺垫。也通过展开质子展现了三体文明中高度发展的科技水平。</p>
                  </div>
                </div>
              </div>
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon-blue.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(21)" />
    </div>
  </div>
</template>

<script>
import bg from '@/assets/img/mobile/threebody-bg5.jpg'
export default {
  name: "ThreeBodyHero",
  data() {
    return {
      swiper: null,
      bg
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .page-bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
    background-color: #000000;
  }

  .page-content {
    position: relative;
    padding-top: 48px;
    min-height: calc(100vh - 48px);
    height: 100%;
    overflow: hidden;

    .arrow-icon {
      position: absolute;
      display: block;
      bottom: 3em;
      left: 0;
      right: 0;
      height: 64px;
      margin: 0 auto;
      cursor: pointer;
    }

    .max-container {
      padding: 0 20px;
      width: calc(100% - 40px);
    }


    .project {
      display: flex;
      margin-bottom: 3em;
      height: 100%;
      align-items: center;

      .project-about {
        background-image: url("~@/assets/img/water.png");
        background-size: contain;
        background-position-y: bottom;
        background-position-x: center;
        background-repeat: no-repeat;

        .project-about-title {
          margin-top: 3em;
          display: flex;
          align-items: flex-end;

          .number {
            font-family: 'Dynalight';
            font-style: normal;
            font-weight: 400;
            font-size: 8.6em;
            line-height: .6em;
          }

          .title-wrapper {
            margin-left: 6px;
            margin-bottom: 6px;

            .title {
              font-style: normal;
              font-weight: 700;
              font-size: 26px;
              line-height: 32px;
              letter-spacing: 0.165em;
            }

            .subtitle {
              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 16px;
            }
          }
        }

        .project-info {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 220%;
          text-align: justify;
          letter-spacing: 0.175em;
          margin-top: 2em;
          margin-bottom: 10em;

          b {
            margin: .5em 0;
            display: block;
            font-size: 1.4em;
            text-align: center;
          }

          p {
            text-indent: 2em;
          }
        }
      }
    }

    .starman-ar-icon {
      margin-top: 2em;
      margin-left: 1em;
      height: 64px;
    }

    .starman-title-bg {
      position: absolute;
      text-align: left;
      top: 20%;
      left: 20px;
      font-style: normal;
      font-weight: 900;
      font-size: 6.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      opacity: 1;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: 1px #FFFFFF;
      user-select: none;
      z-index: -1;
    }

    .threebody-title-bg {
      position: absolute;
      text-align: left;
      bottom: 18%;
      left: -40px;
      font-style: normal;
      font-weight: 900;
      font-size: 8.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      opacity: 1;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: .5px #FFFFFF;
      user-select: none;
      z-index: -1;
    }

    .star-icon1 {
      position: absolute;
      bottom: 24%;
      right: 0;
      width: 164px;
    }

    .logo-icon {
      position: absolute;
      bottom: 4em;
      right: 2em;
      width: 60px;
      height: 60px;
    }

    .city-bg {
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      width: calc(100% + 40px);
      height: calc(100vh + 40px);
      z-index: -1;
    }

  }
</style>