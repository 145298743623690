<template>
  <div>
    <transition name="fade">
      <div v-if="$parent.index === 1" class="page-bg" :style="{backgroundImage: `url(${bg1})`}"></div>
    </transition>
    <div class="page-content">
      <transition name="fade-up-50">
        <div v-if="$parent.index === 1">
          <el-row :gutter="420">
            <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <h1>
                星徒科技
                <span data-title="STARMAN">STARMAN</span>
              </h1>
              <div class="about">
                <p>
                  星徒科技 <br/>
                  星徒科技专注增强现实，专注即将到来的IT革命中最大的一块蛋糕，而想象力是我们唯一的制约。
                </p>
              </div>
            </el-col>
            <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="starman-info">
                <h1>
                  星徒科技<span data-title="STARMAN">STARMAN</span>
                </h1>
                <div class="info">
                  <h2>星徒（上海）科技有限公司</h2>
                  <div class="en-title">Starman Shanghai Technology</div>
                  <div class="subtitle">领先的AR服务提供商</div>
                  <p>击破现实和虚拟的界限，在强大远景的推动下，我们组成了年轻，果敢，富有协同和探索精神，且拥有自主知识产权、高水平研发、产品和美术团队。</p>
                </div>
              </div>
            </el-col>
          </el-row>
          <img class="hero-doll" src="@/assets/img/bgDoll.png" />
          <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
          <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          <div class="next-icon">
            <i class="el-icon-arrow-down" @click="$parent.navTo(2)" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import bg1 from '@/assets/img/bg1.jpg'
export default {
    name: "HeroPage",
    data() {
        return {
          swiper: null,
          bg1
        };
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<style scoped lang="scss">
  .page-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background: center/cover no-repeat;
  }

  .page-content {

    .hero-doll {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      margin: 0 auto;
      z-index: -1;
    }

    .logo-smoke {
      position: absolute;
      width: 110px;
      bottom: 30px;
      left: 80px;
    }

    .logo-icon {
      position: absolute;
      width: 80px;
      bottom: 80px;
      right: 80px;
    }

    .next-icon {
      position: absolute;
      margin: 0 auto;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      color: #FFFFFF;
      font-size: 23px;
      cursor: pointer;
      transition: all linear .1s;

      &:hover {
        transform: translateY(-3px);
      }
    }

    h1 {
      text-align: center;
      position: relative;
      font-weight: 900;
      font-size: 11.5em;
      line-height: 164px;
      color: #FFFFFF;
      display: inline-flex;
      justify-content: center;
      z-index: -1;

      span {
        bottom: -84px;
        display: block;
        position: absolute;
        color: transparent;
        z-index: 0;
        font-family: 'Gantari';
        background: linear-gradient(180deg, rgba(217, 217, 217, 0.27) 0%, rgba(217, 217, 217, 0) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        &::before {
          content: attr(data-title);
          position: absolute;
          -webkit-text-stroke: 1.5px #fff;
          z-index: -2;
        }
      }
    }

    .about {
      padding: 20px;
      padding-left: 80px;
      font-weight: 400;
      font-size: 16px;
      line-height: 220%;
      letter-spacing: 0.175em;
      color: #FBFBFB;
    }

    .info {
      padding-top: 20px;
      padding-right: 80px;

      h2 {
        margin: 0;
        margin-bottom: 10px;
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.055em;
        color: #FBFBFB;
      }

      .en-title {
        margin-bottom: 10px;
        font-weight: 290;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        color: #397FE8;
      }

      .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.39em;
        color: #FBFBFB;
      }

      p {
        font-weight: 700;
        font-size: 17px;
        line-height: 38px;
        letter-spacing: 0.175em;
        color: #FBFBFB;
      }
    }
  }

  @media screen and (max-width: 992px) {
  }

  @media screen and (max-width: 768px) {

  }

  @media screen and (max-width: 576px) {

    .page-content {
      h1, .about {
        display: none;
      }

      .hero-doll {
        top: 8em;
        height: 70%;
        margin-left: -52%;
      }

      .starman-info {
        z-index: 1;
        position: absolute;
        top: 2em;
        width: 50%;

        h1 {
          display: block;
          font-size: 6em;
        }

        .about {
          display: block;
        }
      }
    }

  }

  .fade-up-50-enter-active {
    display: block;
    width: 100%;
    height: 100vh;
  }
</style>