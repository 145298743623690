<template>
  <div>
    <div class="page-content">
      <u-animate
          name="fadeIn"
          delay="0s"
          duration="2s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
      >
        <div class="page-bg" :style="{backgroundImage: `url(${bg1})`}"></div>
      </u-animate>
      <u-animate
          name="fadeIn"
          delay="0s"
          duration="2s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
      >
        <div class="page-bg" :style="{backgroundImage: `url(${bg2})`}"></div>
      </u-animate>
      <div class="max-container">
        <el-row type="flex" justify="center">
          <el-col :span="20">
            <u-animate
                name="zoomInUp"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <div class="project">
                <div class="project-about">
                  <div class="project-about-title">
                    <div class="number">2</div>
                    <div class="title-wrapper">
                      <div class="title">项目内容</div>
                      <div class="subtitle">PROJECT CONTENT</div>
                    </div>
                  </div>
                  <div class="project-info">
                    用户在出游之余能够收获一篇带有独特情感与回忆的游记之外，同样可以获得装扮自己独特虚拟小屋的装扮奖励，将来可以邀请好友共同建设经营专属小屋。
                  </div>
                </div>
              </div>
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon-blue.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(14)" />
      <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
    </div>
  </div>
</template>

<script>
import bg1 from '@/assets/img/mobile/universe-bg3.png'
import bg2 from '@/assets/img/mobile/universe-bg4.png'
export default {
  name: "UniverseContent",
  data() {
    return {
      swiper: null,
      bg1,
      bg2
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .page-bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: center;
    background-size: contain;
  }

  .page-content {
    position: relative;
    padding-top: 48px;
    min-height: calc(100vh - 48px);
    height: 100%;
    overflow: hidden;

    .arrow-icon {
      position: absolute;
      display: block;
      bottom: 3em;
      left: 0;
      right: 0;
      height: 64px;
      margin: 0 auto;
      cursor: pointer;
    }

    .max-container {
      padding: 0 20px;
      width: calc(100% - 40px);
    }

    .universe-logo {
      margin-top: 12em;

      img {
        width: 100%;
      }

      h2 {
        margin: .3em 0;
        font-weight: 800;
        font-size: 3.6em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: center;
      }
    }

    .universe-title-bg {
      position: absolute;
      text-align: right;
      bottom: 25%;
      left: -10%;
      font-style: normal;
      font-weight: 900;
      font-size: 5.4em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      opacity: 1;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: .5px #FFFFFF;
      user-select: none;
      z-index: -1;
    }

    .project {
      margin-top: 1em;
      display: flex;
      height: 100%;
      align-items: center;

      .project-about {
        .project-about-title {
          margin-top: 1em;
          display: flex;
          align-items: flex-end;
          justify-content: right;

          .number {
            font-family: 'Dynalight';
            font-style: normal;
            font-weight: 400;
            font-size: 7.6em;
            line-height: .6em;
          }

          .title-wrapper {
            .title {
              font-style: normal;
              font-weight: 700;
              font-size: 21px;
              line-height: 32px;
              letter-spacing: 0.165em;
            }

            .subtitle {
              font-style: normal;
              font-weight: 400;
              font-size: 11px;
              line-height: 16px;
            }
          }
        }

        .project-info {
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 220%;
          text-align: justify;
          letter-spacing: 0.175em;
          margin-top: 2em;
          text-indent: 2em;
        }
      }
    }

    .star-icon1 {
      position: absolute;
      top: 8em;
      left: -2em;
      width: 120px;
      height: 120px;
    }

    .star-icon2 {
      position: absolute;
      top: 4em;
      right: 0;
      width: 80px;
      height: 80px;
    }

    .logo-icon {
      position: absolute;
      bottom: 4em;
      right: 2em;
      width: 60px;
      height: 60px;
    }

    .city-bg {
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      width: calc(100% + 40px);
      height: calc(100vh + 40px);
      z-index: -1;
    }

  }
</style>