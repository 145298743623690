<template>
  <div>
    <div class="swiper-container swiper-container-draw">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <div class="page-bg"></div>
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="center" :gutter="420">
                <el-col :span="24">
                    <div class="draw-img" >
                      <transition name="fade-up-50">
                        <h2 v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 0">新一代AR读物</h2>
                      </transition>
                      <transition name="fade">
                        <div v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 0">
                          <img src="@/assets/img/draw_cover.png" />
                        </div>
                      </transition>
                    </div>
                </el-col>
              </el-row>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-bg"></div>
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="center" :gutter="80">
                <el-col :span="14">
                  <transition name="fade-right-50">
                    <div class="draw-cover" v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 1">
                      <img src="@/assets/img/draw1.png" />
                    </div>
                  </transition>
                </el-col>
                <el-col :span="10">
                  <transition name="fade-left-50">
                    <div v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 1">
                      <div class="draw-cover">
                        <img src="@/assets/img/draw2.png" />
                      </div>
                      <div class="draw-info">
                        <b>AR绘本</b>
                        <p>《我想要一颗种子》是星徒科技基于增强现实（AR）技术推出的全新儿童启蒙教育产品，采用了平面识别和追踪技术，将惊喜埋藏在书中的每一页，具备动态场景的同时还能与孩子互动。</p>
                        <p>绘本中英双语教学，可以激发孩子学习外语的兴趣。让孩子在故事里学知识，寓教于乐。</p>
                      </div>
                    </div>
                  </transition>
                </el-col>
              </el-row>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-bg"></div>
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="center" :gutter="120">
                <el-col :span="12">

                  <div class="draw-display-cover cover-1">
                    <transition name="fade-up-50">
                      <div v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 2">
                        <h2>可以切换</h2>
                      </div>
                    </transition>
                    <transition name="fade-right-50">
                      <div v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 2">
                        <img src="@/assets/img/draw4.png" />
                      </div>
                    </transition>
                  </div>
                </el-col>
                <el-col :span="12">
                  <transition name="fade-left-50">
                    <div v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 2">
                      <div class="draw-display-cover">
                        <img src="@/assets/img/draw5.png" />
                      </div>
                    </div>
                  </transition>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <transition name="fade-up-50">
                    <div class="draw-info" v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 2">
                      <p>我们采用了二维增强现实内容与三维增强现实内容相结合的形式，不但可以将人物之间的对话互动进行生动展现，更能让绘本中的人物形象跃然纸上，传递给孩子沉浸式的读书体验，用AR等技术，通过智能终端（手机、平板电脑、智能硬件等）充分调动眼、耳、口、手、脑等感官系统，提高孩子的学习兴趣，刺激大脑开发，其全新的方式来教授孩子们日常生活中遇到的动植物并以好玩的互动形式来强化记忆，让孩子寓教于乐，轻松启智。</p>
                    </div>
                  </transition>
                </el-col>
              </el-row>
            </div>
            <div class="display-text">
              <transition name="fade-up-50">
                <div v-if="$parent.index === 3 && swiper!=null && swiper.realIndex === 2">
                  <h2>显示模式</h2>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="next-icon">
      <i class="el-icon-arrow-down" @click="$parent.navTo(5)" />
    </div>
  </div>
</template>

<script>
import bg3 from '@/assets/img/bg3.jpg'
import bg4 from '@/assets/img/bg4.jpg'
import Swiper from 'swiper'
import "swiper/dist/css/swiper.min.css";
export default {
  name: "DrawPage",
  data() {
    return {
      swiper: null,
      bg3,
      bg4
    };
  },
  mounted() {
    this.myswiper()
  },
  methods: {
    myswiper: function() {
      let that = this
      this.swiper = new Swiper('.swiper-container-draw', {
        mousewheel: true,
        on:{
          slideNextTransitionEnd: function(){
            if (this.activeIndex === 2) {
              that.$parent.isNextScroll = true
            }
            that.$parent.isPrevScroll = false
          },
          slidePrevTransitionEnd: function(){
            if (this.activeIndex === 0) {
              that.$parent.isPrevScroll = true
            }
            that.$parent.isNextScroll = false
          }
        },
      });
    }
  },
};
</script>
<style scoped lang="scss">

  .swiper-container {
    background: linear-gradient(0deg, #FFF1EB 0%, #ACE0F9 100%);
    height: 100vh;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .el-carousel {
    ::v-deep {
      .el-carousel__container {
        height: 100vh;
      }

      .el-carousel__indicators {
        display: none;
      }
    }
  }

  .next-icon {
    position: absolute;
    margin: 0 auto;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    color: #FFFFFF;
    font-size: 23px;
    cursor: pointer;
    transition: all linear .1s;
    z-index: 2;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .time-text {
    position: absolute;
    right: 0;
    top: calc((100vh - 30px) / 2);
    bottom: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    letter-spacing: 0.175em;
    color: #FBFBFB;
    transform: rotate(-90deg);
    z-index: 2;
  }

  .page-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: contain;
    background: linear-gradient(0deg, #FFF1EB 0%, #ACE0F9 100%);
  }

  .draw-about-cover {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .page-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .logo-smoke {
      position: absolute;
      width: 110px;
      bottom: 30px;
      left: 80px;
    }

    .logo-icon {
      position: absolute;
      width: 80px;
      bottom: 80px;
      right: 80px;
    }

    .draw-img {
      position: relative;
      display: flex;
      justify-content: center;

      h2 {
        position: absolute;
        font-family: 'Gantari';
        font-size: 11em;
        letter-spacing: 0.215em;
        background: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff70;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-text-stroke: 0.6px #FBFBFB;
        margin: auto;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 2;
      }

      img {
        width: 100%;
        z-index: 1;
      }
    }

    .draw-cover {
      width: 100%;
      img {
        width: 100%;
      }
    }

    .draw-info {
      color: #656F77;
      font-size: 1.2em;
      line-height: 2em;
      margin-top: 20px;

      p {
        text-indent: 2em;
        font-weight: 500;
      }

      b {
        font-size: 1.4em;
      }
    }

    .draw-display-cover {
      width: 100%;

      &.cover-1 {
        margin-top: 5%;

        h2 {
          font-family: 'Gantari';
          font-size: 6em;
          background: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: #ffffff70;
          background-clip: text;
          text-fill-color: transparent;
          -webkit-text-stroke: 0.6px #FBFBFB;
          margin: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .display-text {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Gantari';
      font-size: 22em;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff70;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: 0.6px #FBFBFB;
      margin: 0;
      left: 0;
      right: 0;

      h2 {
        font-size: 1em;
      }
    }
  }
</style>