<template>
  <div>
    <div class="page-content">
      <u-animate
          name="fadeIn"
          delay="0s"
          duration="2s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
      >
        <div class="page-bg" :style="{backgroundImage: `url(${bg})`}"></div>
      </u-animate>
      <div class="starman-title-bg">
        <u-animate
            name="slideInRight"
            delay="0s"
            duration="1s"
            :iteration="1"
            :offset="0"
            animateClass="animated"
            :begin="false"
        >
          STAR<br/>MAN
        </u-animate>
      </div>
      <div class="max-container">
        <el-row type="flex" justify="center">
          <el-col :span="22">
            <u-animate
                name="slideInRight"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <div class="ar-title">
                <h2>增强现实+</h2>
              </div>
            </u-animate>
            <u-animate
                name="slideInRight"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <img class="starman-ar-icon" src="@/assets/img/mobile/starman-ar-icon.svg" />
            </u-animate>
            <u-animate
                name="slideInUp"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <img class="starman-ar-list" src="@/assets/img/mobile/ar-list.svg" />
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(22)" />
    </div>
  </div>
</template>

<script>
import bg from '@/assets/img/mobile/ar-hero-bg.jpg'
export default {
  name: 'ARHero',
  data() {
    return {
      swiper: null,
      bg
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.page-bg {
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  background-color: #000000;
}

.page-content {
  position: relative;
  padding-top: 48px;
  min-height: calc(100vh - 48px);
  height: 100%;
  overflow: hidden;

  .arrow-icon {
    position: absolute;
    display: block;
    bottom: 3em;
    left: 0;
    right: 0;
    height: 64px;
    margin: 0 auto;
    cursor: pointer;
  }

  .max-container {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .ar-title {
    margin-top: 6em;

    img {
      width: 100%;
    }

    h2 {
      margin: .3em 0;
      font-weight: 800;
      font-size: 4.5em;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: center;

      b {
        position: relative;
        display: block;
        text-align: right;
        margin-right: 1.2em;
        font-size: .5em;
      }
    }
  }

  .starman-ar-icon {
    float: right;
    margin-top: 2em;
    margin-left: 1em;
    height: 64px;
  }

  .starman-ar-list {
    margin-top: 3em;
    width: 100%;
  }

  .starman-title-bg {
    position: absolute;
    text-align: left;
    top: 10%;
    left: 20px;
    font-style: normal;
    font-weight: 900;
    font-size: 6.25em;
    font-family: 'Gantari';
    line-height: .9em;
    letter-spacing: 0.205em;
    opacity: 1;
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-stroke: 1px #397FE8;
    user-select: none;
    z-index: 0;
  }

  .threebody-title-bg {
    position: absolute;
    text-align: left;
    bottom: 18%;
    left: -40px;
    font-style: normal;
    font-weight: 900;
    font-size: 8.25em;
    font-family: 'Gantari';
    line-height: .9em;
    letter-spacing: 0.205em;
    opacity: 1;
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-stroke: .5px #FFFFFF;
    user-select: none;
    z-index: 0;
  }

  .star-icon1 {
    position: absolute;
    bottom: 24%;
    right: 0;
    width: 164px;
  }

  .logo-icon {
    position: absolute;
    bottom: 4em;
    right: 2em;
    width: 60px;
    height: 60px;
  }

  .city-bg {
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    height: calc(100vh + 40px);
    z-index: -1;
  }

}
</style>