<template>
  <div>
    <NavBar :navTo="navTo" ref="navbar" />
    <u-animate-container>
      <Hero class="page" ref="page1" id="page1" />
      <AboutUs class="page" ref="page2" id="page2" />
      <!-- <Team class="page" ref="page3" id="page3" />
      <TeamBoss class="page" ref="page4" id="page4" />
      <TeamConst class="page" ref="page5" id="page5" />
      <TeamYY class="page" ref="page6" id="page6" />
      <TeamBoogie class="page" ref="page7" id="page7" /> -->
      <DrawHero class="page" ref="page3" id="page3" />
      <DrawAbout class="page" ref="page4" id="page4" />
      <DrawAbout2 class="page" ref="page5" id="page5" />
      <UniverseHero class="page" ref="page6" id="page6" />
      <UniverseSummary class="page" ref="page7" id="page7" />
      <UniverseContent class="page" ref="page8" id="page8" />
      <UniverseContent2 class="page" ref="page9" id="page9" />
      <UniverseRealAR class="page" ref="page10" id="page10" />
      <ThreeBodyHero class="page" ref="page11" id="page11" />
      <ThreeBodySummary class="page" ref="page12" id="page12" />
      <ThreeBodyContent class="page" ref="page13" id="page13" />
      <ThreeBodyContent2 class="page" ref="page14" id="page14" />
      <ThreeBodyContent3 class="page" ref="page15" id="page15" />
      <ARHero class="page" ref="page16" id="page16" />
      <ARPlay class="page" ref="page17" id="page17" />
      <ARVideo class="page" ref="page18" id="page18" />
      <ARGame class="page" ref="page19" id="page19" />
      <ARGo class="page" ref="page20" id="page20" />
      <ARShop class="page" ref="page21" id="page21" />
      <AREduction class="page" ref="page22" id="page22" />
      <JoinUs class="page" ref="page23" id="page23" />
    </u-animate-container>
    <Footer/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Hero from '@/views/Home/mobile/components/Hero.vue'
import AboutUs from '@/views/Home/mobile/components/AboutUs.vue'
// import Team from '@/views/Home/mobile/components/Team'
// import TeamBoss from '@/views/Home/mobile/components/Team-Boss.vue'
// import TeamConst from '@/views/Home/mobile/components/Team-Const.vue'
// import TeamYY from '@/views/Home/mobile/components/Team-YY.vue'
// import TeamBoogie from '@/views/Home/mobile/components/Team-Boogie'
import DrawHero from '@/views/Home/mobile/components/DrawHero'
import DrawAbout from '@/views/Home/mobile/components/DrawAbout'
import DrawAbout2 from '@/views/Home/mobile/components/DrawAbout2'
import UniverseHero from '@/views/Home/mobile/components/UniverseHero'
import UniverseSummary from '@/views/Home/mobile/components/UniverseSummary'
import UniverseContent from "@/views/Home/mobile/components/UniverseContent"
import UniverseContent2 from "@/views/Home/mobile/components/UniverseContent2"
import UniverseRealAR from "@/views/Home/mobile/components/UniverseRealAR"
import ThreeBodyHero from "@/views/Home/mobile/components/ThreeBodyHero"
import ThreeBodySummary from "@/views/Home/mobile/components/ThreeBodySummary"
import ThreeBodyContent from "@/views/Home/mobile/components/ThreeBodyContent"
import ThreeBodyContent2 from "@/views/Home/mobile/components/ThreeBodyContent2"
import ThreeBodyContent3 from "@/views/Home/mobile/components/ThreeBodyContent3";
import ARHero from "@/views/Home/mobile/components/ARHero";
import ARPlay from "@/views/Home/mobile/components/ARPlay";
import ARVideo from "@/views/Home/mobile/components/ARVideo";
import ARGame from "@/views/Home/mobile/components/ARGame";
import ARGo from "@/views/Home/mobile/components/ARGo";
import ARShop from "@/views/Home/mobile/components/ARShop";
import AREduction from "@/views/Home/mobile/components/AREduction";
import JoinUs from "@/views/Home/mobile/components/JoinUs";
import Footer from "@/components/Footer";
import 'animate.css';

export default {
  name: 'mobileHome',
  components: {
    NavBar,
    Footer,
    Hero,
    AboutUs,
    // Team,
    // TeamBoss,
    // TeamConst,
    // TeamYY,
    // TeamBoogie,
    DrawHero,
    DrawAbout,
    DrawAbout2,
    UniverseHero,
    UniverseSummary,
    UniverseContent,
    UniverseContent2,
    UniverseRealAR,
    ThreeBodyHero,
    ThreeBodySummary,
    ThreeBodyContent,
    ThreeBodyContent2,
    ThreeBodyContent3,
    ARHero,
    ARPlay,
    ARVideo,
    ARGame,
    ARGo,
    ARShop,
    AREduction,
    JoinUs,
  },
  data() {
    return {
      pages: 0,
    }
  },
  mounted() {
    this.pages = document.querySelectorAll(".page").length
  },
  methods: {
    navTo(e) {
      console.log(e)
    },
  }
}
</script>

<style scoped>

</style>