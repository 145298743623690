<template>
  <div>
    <div class="page-content">
      <div class="max-container">
        <el-row>
          <el-col :span="24">
            <u-animate
                name="fadeIn"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <h1>
                星徒科技
                <span data-title="STARMAN">STARMAN</span>
              </h1>
              <div class="about-info">
                <el-row>
                  <el-col :span="16">
                    <div class="about-title">
                      星徒（上海）科技有限公司
                    </div>
                    <div class="about-subtitle">
                      领先的AR服务提供商
                    </div>
                    <div class="about">
                      <p>
                        击破现实和虚拟的界限，在强大愿景的推动下，我们组成了年轻，果敢，富有协同和探索精神，且拥有自主知识产权、高水平研发、产品和美术团队。
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="8"></el-col>
                  <el-col :span="24">
                    <img class="star-icon-bg" src="@/assets/img/mobile/hero-star-bg.svg" />
                  </el-col>
                </el-row>
              </div>
              <div class="hero-doll-wrapper">
                <img class="hero-doll" src="@/assets/img/mobile/bgDoll.png" />
                <img class="hero-doll-bg" src="@/assets/img/mobile/bgDoll.png" />
              </div>
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon-blue.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(2)" />
      <img class="city-bg" src="@/assets/img/mobile/city-bg.svg" />
    </div>
  </div>
</template>

<script>
import bg1 from '@/assets/img/mobile/bgDoll.png'
export default {
  name: 'MobileHero',
  data() {
    return {
      bg1
    }
  },
  methods: {
    getTarget() {
      return this.$el;
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-bg {
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .page-content {
    padding-top: 48px;
    min-height: calc(100vh - 48px);
    height: 100%;
    overflow: hidden;

    .arrow-icon {
      position: absolute;
      display: block;
      bottom: 3em;
      left: 0;
      right: 0;
      height: 64px;
      margin: 0 auto;
      cursor: pointer;
    }

    h1 {
      margin: 0;
      margin-top: .1em;
      text-align: center;
      position: relative;
      font-weight: 900;
      font-size: 5.6em;
      line-height: 164px;
      color: #FFFFFF;
      display: inline-flex;
      justify-content: center;
      z-index: -1;
      width: 100%;

      span {
        bottom: -35px;
        display: block;
        position: absolute;
        color: transparent;
        z-index: 0;
        font-family: 'Gantari';
        background: linear-gradient(180deg, rgba(217, 217, 217, 0.27) 0%, rgba(217, 217, 217, 0) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        &::before {
          content: attr(data-title);
          position: absolute;
          -webkit-text-stroke: 1px #fff;
          z-index: -2;
        }
      }
    }

    .about-info {
      margin-top: 16px;
      padding: 0 20px;

      .about-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.055em;
        color: #FBFBFB;
      }

      .about-subtitle {
        margin-bottom: 22px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.39em;
      }

      .about {
        position: absolute;
        left: 0;
        width: 60%;

        p {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          text-align: justify;
          letter-spacing: 0;
        }
      }
    }
  }

  .star-icon-bg {
    position: absolute;
    width: 100%;
    margin-top: 10em;
  }

  .city-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -1;
  }

  .hero-doll-wrapper {
    position: relative;

    .hero-doll {
      width: 100%;
      padding-bottom: 2em;
    }

    .hero-doll-bg {
      top: -15%;
      left: 0;
      width: 130%;
      position: absolute;
      z-index: -1;
      opacity: .3;
      left: -25%;
    }
  }

</style>