<template>
  <div>
    <div class="page-content">
      <div class="page-bg" :style="{backgroundImage: `url(${bg})`}"></div>
      <div class="universe-title-bg">
        <u-animate
            name="zoomIn"
            delay="0s"
            duration="1s"
            :iteration="1"
            :offset="0"
            animateClass="animated"
            :begin="false"
        >
          STAR<br/>MAN
        </u-animate>
      </div>
      <div class="max-container">
        <el-row>
          <el-col :span="24">
            <u-animate
                name="zoomIn"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <div class="universe-logo">
                <img src="@/assets/img/mobile/universe-logo.svg" />
                <h2>星&nbsp;&nbsp;&nbsp;宇&nbsp;&nbsp;&nbsp;宙</h2>
              </div>
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon-blue.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(12)" />
      <img class="star-icon1" src="@/assets/img/mobile/star-icon.svg" />
      <img class="star-icon2" src="@/assets/img/mobile/star-icon.svg" />
      <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
    </div>
  </div>
</template>

<script>
import bg from '@/assets/img/mobile/universe-bg.jpg'
export default {
  name: "UniverseHero",
  data() {
    return {
      swiper: null,
      bg
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .page-bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
    background-color: #000000;
  }

  .page-content {
    position: relative;
    padding-top: 48px;
    min-height: calc(100vh - 48px);
    height: 100%;
    overflow: hidden;

    .arrow-icon {
      position: absolute;
      display: block;
      bottom: 3em;
      left: 0;
      right: 0;
      height: 64px;
      margin: 0 auto;
      cursor: pointer;
    }

    .max-container {
      padding: 0 20px;
      width: calc(100% - 40px);
    }

    .universe-logo {
      margin-top: 12em;

      img {
        width: 100%;
      }

      h2 {
        margin: .3em 0;
        font-weight: 800;
        font-size: 3.6em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: center;
      }
    }

    .universe-title-bg {
      position: absolute;
      text-align: right;
      bottom: 20%;
      left: -20px;
      font-style: normal;
      font-weight: 900;
      font-size: 6.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      opacity: 1;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: .5px #FFFFFF;
      user-select: none;
      z-index: -1;
    }

    .star-icon1 {
      position: absolute;
      top: 8em;
      left: -2em;
      width: 120px;
      height: 120px;
    }

    .star-icon2 {
      position: absolute;
      top: 4em;
      right: 0;
      width: 80px;
      height: 80px;
    }

    .logo-icon {
      position: absolute;
      bottom: 4em;
      right: 2em;
      width: 60px;
      height: 60px;
    }

    .city-bg {
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      width: calc(100% + 40px);
      height: calc(100vh + 40px);
      z-index: -1;
    }

  }
</style>