<template>
  <div>
    <div class="page-content">
      <div class="max-container">
        <el-row>
          <el-col :span="24">
            <div class="draw-images">
              <u-animate
                  name="bounceInRight"
                  delay="0s"
                  duration="1s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
              >
                <img class="draw-cover1" src="@/assets/img/mobile/draw4.png" />
              </u-animate>
              <u-animate
                  name="bounceInLeft"
                  delay="0s"
                  duration="1s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
              >
                <img class="draw-cover2" src="@/assets/img/mobile/draw5.png" />
              </u-animate>
            </div>
            <u-animate
                name="bounceInUp"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <div class="draw-content">
                <p>我们采用了二维增强现实内容与三维增强现实内容相结合的形式，不但可以将人物之间的对话互动进行生动展现，更能让绘本中的人物形象跃然纸上，传递给孩子沉浸式的读书体验，用AR等技术，通过智能终端（手机、平板电脑、智能硬件等）充分调动眼、耳、口、手、脑等感官系统，提高孩子的学习兴趣，刺激大脑开发，其全新的方式来教授孩子们日常生活中遇到的动植物并以好玩的互动形式来强化记忆，让孩子寓教于乐，轻松启智。</p>
              </div>
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon-blue.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(11)" />
      <img class="city-bg" src="@/assets/img/mobile/city-bg.svg" />
    </div>
  </div>
</template>

<script>
import bg2 from '@/assets/img/bg2.jpg'
export default {
  name: "TeamMobilePage",
  data() {
    return {
      swiper: null,
      bg2
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.page-content {
  background: linear-gradient(0deg, #FFF1EB 0%, #ACE0F9 100%);
  position: relative;
  padding-top: 48px;
  min-height: calc(100vh - 48px);
  height: 100%;
  overflow: hidden;

  .arrow-icon {
    position: absolute;
    display: block;
    bottom: 3em;
    left: 0;
    right: 0;
    height: 64px;
    margin: 0 auto;
    cursor: pointer;
  }

  .max-container {
    padding: 0 20px;
    width: calc(100% - 40px);
    padding-bottom: 7em;
  }

  h2 {
    position: relative;
    margin: 0 -20px;
    margin-top: 20px;
    margin-bottom: 1em;
    font-family: 'Gantari';
    font-size: 3.6em;
    letter-spacing: 0.1em;
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #FBFBFB;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-stroke: 0.6px #FBFBFB;
    text-align: center;
    display: flex;
    justify-content: center;

    &:before {
      position: absolute;
      bottom: -16px;
      content: '新一代AR读物';
      font-family: 'Gantari';
      font-size: 1.05em;
      letter-spacing: 0.1em;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff00;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: 0.6px #FBFBFB;
      text-align: center;
    }
  }

  .draw-images {
    margin-top: 20px;
    position: relative;

    .draw-cover1 {
      margin: 0 -20px;
      margin-top: 0;
      width: calc(100% + 40px);
    }

    .draw-cover2 {
      margin: 0 -20px;
      margin-top: 20px;
      margin-left: 40px;
      width: calc(100% - 20px);
    }

    .draw-icon {
      position: absolute;
      bottom: 5%;
      left: 0;
      width: 26%;
    }
  }

  .draw-content {
    margin-top: 1em;
    color: #656F77;
    font-size: 14px;
    font-weight: 600;
    line-height: 180%;
    text-align: justify;
    letter-spacing: 0.175em;

    b {
      text-indent: 0em;
      margin-bottom: 10px;
      display: block;
      font-size: 1.3em;
    }

    p {
      text-indent: 2em;
    }
  }

  .city-bg {
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    height: calc(100vh + 40px);
    z-index: -1;
  }

}


</style>