<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
html body {
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Roboto,PingFang SC,Noto Sans CJK,WenQuanYi Micro Hei,Microsoft YaHei;
  font-size: 14px;
  color: #FFFFFF;
  background: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
     height: 0 !important;
     width:0px !important;
  }
}

img {
  user-select: none;
}

.max-container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
}

@media screen and (max-width: 1920px) {
  .max-container {
    max-width: 1440px;
  }
}

@media screen and (max-width: 1280px) {
  .max-container {
    max-width: 1140px;
  }
}

@media screen and (max-width: 992px) {
  .max-container {
    max-width: 960px;
  }
}

@media screen and (max-width: 768px) {
  .max-container {
    max-width: 720px;
  }
}

@media screen and (max-width: 576px) {
  .max-container {
    max-width: 540px;
  }
}

/**
  官网特效
 */
.fade-enter-active {
  transition: opacity 1.3s ease;
}

.fade-leave-active {
  transition: opacity 1.3s ease;
}

.fade-enter, .fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */ {
  opacity: 0;
}


.fade-left-50-enter-active {
  display: block;
  -webkit-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  -webkit-animation: fade-left-50 0.5s ease 0.2s forwards;
  animation: fade-left-50 0.5s ease 0.2s forwards;
}

.fade-left-50-leave-active {
  opacity: 0;
}

.fade-left-50-enter, .fade-left-50-leave-to {
  opacity: 0;
}

@-webkit-keyframes fade-left-50 {
  0% {
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fade-left-50 {
  0% {
    -webkit-transform: translateX(20%);
    transform: translateX(20%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.fade-right-50-enter-active {
  display: block;
  -webkit-transform: translateX(-20%);
  -ms-transform: translateX(-20%);
  transform: translateX(-20%);
  -webkit-animation: fade-right-50 0.5s ease 0.2s forwards;
  animation: fade-right-50 0.5s ease 0.2s forwards;
}

.fade-left-50-leave-active {
  opacity: 0;
}

.fade-left-50-enter, .fade-left-50-leave-to {
  opacity: 0;
}

@-webkit-keyframes fade-right-50 {
  0% {
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fade-right-50 {
  0% {
    -webkit-transform: translateX(-20%);
    transform: translateX(-20%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.fade-up-50-enter-active {
  display: block;
  -webkit-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
  -webkit-animation: fade-up-50 0.5s ease 0.2s forwards;
  animation: fade-up-50 0.5s ease 0.2s forwards;
  transition: opacity 1s ease;
}

.fade-up-50-leave-active {
  transition: opacity 1s ease;
}

.fade-up-50-enter, .fade-up-50-leave-to {
  opacity: 0;
}

@-webkit-keyframes fade-up-50 {
  0% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fade-up-50 {
  0% {
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.fade-down-50-enter-active {
  display: block;
  -webkit-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
  -webkit-animation: fade-down-50 0.5s ease 0.2s forwards;
  animation: fade-down-50 0.5s ease 0.2s forwards;
  transition: opacity 1s ease;
}

.fade-down-50-leave-active {
  transition: opacity 1s ease;
}

.fade-down-50-enter, .fade-down-50-leave-to {
  opacity: 0;
}

@-webkit-keyframes fade-down-50 {
  0% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fade-down-50 {
  0% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
