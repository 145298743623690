<template>
  <div>
    <div class="swiper-container swiper-container-universe">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <div class="page-bg" :style="{backgroundImage: `url(${universeBg})`}"></div>
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="center">
                <el-col :span="24">
                  <transition name="fade-up-50">
                    <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 0">
                      <div class="universe-title">
                        <img src="@/assets/img/universe-title.svg" />
                        <h2>星宇宙</h2>
                      </div>
                    </div>
                  </transition>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-up-50">
                    <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 1">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title">
                            <div class="number">1</div>
                            <div class="title-wrapper">
                              <div class="title">项目概述</div>
                              <div class="subtitle">PROJECT SUMMARY</div>
                            </div>
                          </div>
                          <div class="project-info">
                            本项目致力于为热爱出行的用户打造一个以AR形式分享、共建的旅游垂类内容社区。用户得以使用全新的创作形式以及工具为每次出行留下独特的回忆，在线上和线下以截然不同的AR形式展示于他人眼前。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                  <transition name="fade-left-50">
                    <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 1">
                      <img class="universe-cover1" src="@/assets/img/universe-cover1.jpg" />
                    </div>
                  </transition>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="17">
                </el-col>
                <el-col :span="7">
                  <transition name="fade-up-50">
                    <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 2">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="number">2</div>
                            <div class="title-wrapper">
                              <div class="title">项目内容</div>
                              <div class="subtitle">PROJECT CONTENT</div>
                            </div>
                          </div>
                          <div class="project-info">
                            搜集内容
                            用户在出游之余能够收获一篇带有独特情感与回忆的游记之外，同样可以获得装扮自己独特虚拟小屋的装扮奖励，将来可以邀请好友共同建设经营专属小屋。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
              </el-row>
            </div>
            <div class="universe-cover2">
              <transition name="fade-up-50">
                <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 2">
                  <img src="@/assets/img/universe-cover2.png" />
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <transition name="fade-up-50">
            <div class="page-bg2" v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 3" :style="{backgroundImage: `url(${universeBg2})`}"></div>
          </transition>
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="17">
                </el-col>
                <el-col :span="7">
                  <transition name="fade-up-50">
                    <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 3">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="number">3</div>
                            <div class="title-wrapper">
                              <div class="title">模型内容</div>
                              <div class="subtitle">MODEL CONTENT</div>
                            </div>
                          </div>
                          <div class="project-info">
                            用户在足不出户的情况下就能够一览目的地的名胜模型以及他人游览时留下的旅游攻略，体验身临其境的沉浸感。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
              </el-row>
            </div>
            <div class="universe-cover3">
              <transition name="fade-up-50">
                <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 3">
                  <img src="@/assets/img/universe-cover3.png" />
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <transition name="fade">
            <div class="page-bg" :style="{backgroundImage: `url(${universeBg3})`}" v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 4"></div>
          </transition>
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-up-50">
                    <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 4">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="number">4</div>
                            <div class="title-wrapper">
                              <div class="title">实景AR</div>
                              <div class="subtitle">REAL AR</div>
                            </div>
                          </div>
                          <div class="project-info">
                            线下AR内容
                            用户在游览过程中可以实现“所见即所得”，能够在以AR形式展示自己的旅游攻略导览的同时，发现他人在此地留下的感想。实现了跨时空的交流。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <div class="universe-cover4">
              <transition name="fade-up-50">
                <div v-if="$parent.index === 4 && swiper!=null && swiper.realIndex === 4">
                  <img src="@/assets/img/universe-cover4.png" />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="next-icon">
      <i class="el-icon-arrow-down" @click="$parent.navTo(6)" />
    </div>
  </div>
</template>

<script>
import universeBg from '@/assets/img/universe-bg.jpg'
import universeBg2 from '@/assets/img/universe-bg2.jpg'
import universeBg3 from '@/assets/img/universe-bg3.jpg'
import Swiper from 'swiper'
import "swiper/dist/css/swiper.min.css";
export default {
  name: "DrawPage",
  data() {
    return {
      swiper: null,
      universeBg,
      universeBg2,
      universeBg3,
    };
  },
  mounted() {
    this.myswiper()
  },
  methods: {
    myswiper: function() {
      let that = this
      this.swiper = new Swiper('.swiper-container-universe', {
        mousewheel: true,
        on:{
          slideNextTransitionEnd: function(){
            if (this.activeIndex === 4) {
              that.$parent.isNextScroll = true
            }
            that.$parent.isPrevScroll = false
          },
          slidePrevTransitionEnd: function(){
            if (this.activeIndex === 0) {
              that.$parent.isPrevScroll = true
            }
            that.$parent.isNextScroll = false
          }
        },
      });
    }
  },
};
</script>
<style scoped lang="scss">

  .swiper-container {
    height: 100vh;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .next-icon {
    position: absolute;
    margin: 0 auto;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    color: #FFFFFF;
    font-size: 23px;
    cursor: pointer;
    transition: all linear .1s;
    z-index: 2;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .time-text {
    position: absolute;
    right: 0;
    top: calc((100vh - 30px) / 2);
    bottom: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    letter-spacing: 0.175em;
    color: #FBFBFB;
    transform: rotate(-90deg);
    z-index: 2;
  }

  .page-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
  }

  .page-bg2 {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: contain;
    background-color: #060606;
  }

  .page-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .logo-smoke {
      position: absolute;
      width: 110px;
      bottom: 30px;
      left: 80px;
    }

    .logo-icon {
      position: absolute;
      width: 80px;
      bottom: 80px;
      right: 80px;
    }

    .universe-title {
      display: block;
      text-align: center;

      img {
        height: 7em;
      }

      h2 {
        font-size: 4em;
        letter-spacing: 1em;
      }
    }

    .project {
      display: flex;
      height: 100%;
      align-items: center;
      .project-about {
        .project-about-title {
          display: flex;
          align-items: flex-end;
          .number {
            font-family: 'Dynalight';
            font-style: normal;
            font-weight: 400;
            font-size: 14em;
            line-height: .6em;
          }
          .title-wrapper {
            margin-bottom: 10px;

            .title {
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 46px;
              letter-spacing: 0.165em;
            }

            .subtitle {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.165em;
            }
          }
        }

        .project-info {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 220%;
          text-align: justify;
          letter-spacing: 0.175em;
          margin-top: 2em;
          text-indent: 2em;
        }
      }
    }

    .universe-cover1 {
      width: 100%;
    }

    .universe-cover2 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 62%;
    }

    .universe-cover3 {
      position: absolute;
      right: 30%;
      bottom: 0;
      width: 40%;

      img {
        width: 100%;
      }
    }

    .universe-cover4 {
      position: absolute;
      left: 40%;
      bottom: 0;
      width: 50%;

      img {
        width: 100%;
      }
    }
  }
</style>