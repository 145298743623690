<template>
  <div>
    <transition name="fade">
      <div v-if="$parent.index === 2" class="page-bg" :style="{backgroundImage: `url(${bg2})`}"></div>
    </transition>
    <div class="page-content">
      <el-row type="flex" justify="end">
        <el-col :span="10">
          <transition name="fade-up-50">
            <div v-if="$parent.index === 2">
              <div class="info">
                <h2>公司概述</h2>
                <div class="en-title">Company overview</div>
                <p>
                  前身
                  <br />
                  我司前身为MC团队，自14年主要与网易、斗鱼、优酷、腾讯、联合国等举办Minecraft、LEGO无限赛事，制作和协助过多部刘慈欣IP改编MC动画，与共青团等公益组织有过多次公益动画联动，有丰富的赛事活动、游戏开发、IP产品打造经验。
                </p>
                <br />
                <div class="subtitle">脚踏现实，直面未来</div>
                <p>
                  我们一直保持创业热情和年轻，热爱猫猫，热爱生活和游戏，严谨而不严肃，专业而知变通，不为自己设置边界循规蹈矩。 我们看到不确定性背后的机遇，看到艰苦卓绝背后的成果，看到天马行空背后的力量——看到我们所看到的新未来，而我们将选择直面现实，承认现实，挑战现实并创造实现属于我们自己的现实。
                </p>
              </div>
            </div>
          </transition>
        </el-col>
      </el-row>
      <img class="logo-white" src="@/assets/img/logo-white.svg" />
      <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
      <div class="next-icon">
        <i class="el-icon-arrow-down" @click="$parent.navTo(3)" />
      </div>
    </div>
    <div class="time-text">PAGE:02/15</div>
  </div>
</template>

<script>
import bg2 from '@/assets/img/bg2.jpg'
export default {
    name: "AboutUsPage",
    data() {
        return {
          swiper: null,
          bg2
        };
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<style scoped lang="scss">
  .page-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background: center/cover no-repeat;
    background-position: top;
  }

  .time-text {
    position: absolute;
    right: 0;
    top: calc((100vh - 30px) / 2);
    bottom: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    letter-spacing: 0.175em;
    color: #FBFBFB;
    transform: rotate(-90deg);
    z-index: 2;
  }

  .page-content {
    padding-top: 4em;

    .next-icon {
      position: absolute;
      margin: 0 auto;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      color: #FFFFFF;
      font-size: 23px;
      cursor: pointer;
      transition: all linear .1s;

      &:hover {
        transform: translateY(-3px);
      }
    }

    .logo-white {
      position: absolute;
      height: 50px;
      bottom: 50px;
      left: 40px;
    }

    .logo-icon {
      position: absolute;
      width: 80px;
      bottom: 60px;
      right: 40px;
    }

    .next-icon {
      position: absolute;
      margin: 0 auto;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      color: #FFFFFF;
      font-size: 23px;
      cursor: pointer;
      transition: all linear .1s;

      &:hover {
        transform: translateY(-3px);
      }
    }

    .info {
      padding-top: 60px;
      padding-left: 60px;
      padding-right: 215px;

      h2 {
        margin: 0;
        margin-bottom: 10px;
        font-weight: 900;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.055em;
        color: #FBFBFB;
      }

      .en-title {
        margin-bottom: 10px;
        font-weight: 290;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        color: #397FE8;
      }

      .subtitle {
        font-weight: 900;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.39em;
        color: #FBFBFB;
      }

      p {
        font-size: 17px;
        line-height: 36px;
        letter-spacing: 0.175em;
        color: #FBFBFB;
      }
    }
  }
</style>