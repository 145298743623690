<template>
  <div>
    <div class="page-content">
      <u-animate
          name="fadeIn"
          delay="0s"
          duration="2s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
      >
        <div class="page-bg" :style="{backgroundImage: `url(${bg})`}"></div>
      </u-animate>
      <div class="starman-title-bg">
        <u-animate
            name="slideInRight"
            delay="0s"
            duration="1s"
            :iteration="1"
            :offset="0"
            animateClass="animated"
            :begin="false"
        >
          VERSE
        </u-animate>
      </div>
      <div class="max-container">
        <el-row type="flex" justify="center">
          <el-col :span="22">
            <div class="ar-title">
              <u-animate
                  name="slideInRight"
                  delay="0s"
                  duration="1s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
              >
                <h2>+加入我们</h2>
              </u-animate>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="joinus-wrapper">
        <div class="max-container">
          <u-animate
              name="slideInUp"
              delay="0s"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false"
          >
            <div class="title">招聘岗位</div>
          </u-animate>
          <u-animate
              name="slideInUp"
              delay="0s"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false"
          >
            <el-row :gutter="10">
              <el-col :span="8">
                <div class="joinus-list-title">开发部：</div>
                <div class="joinus-list-item">人工智能工程师</div>
                <div class="joinus-list-item">后端开发工程师</div>
                <div class="joinus-list-item">计算机视觉工程师</div>
                <div class="joinus-list-item">WEB前端工程师</div>
                <div class="joinus-list-item">Unity3D工程师</div>
              </el-col>
              <el-col :span="8">
                <div class="joinus-list-title">美术部：</div>
                <div class="joinus-list-item">三维角色/场景设计师</div>
                <div class="joinus-list-item">平面设计</div>
                <div class="joinus-list-item">Unity3D美术工程师</div>
                <div class="joinus-list-item">Unity3D技术美术</div>
                <div class="joinus-list-item">GUI界面美术</div>
              </el-col>
              <el-col :span="8">
                <div class="joinus-list-title">产品部：</div>
                <div class="joinus-list-item">产品经理</div>
                <div class="joinus-list-item">UX交互设计</div>
              </el-col>
            </el-row>
          </u-animate>
          <u-animate
              name="slideInUp"
              delay="0s"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false"
          >
            <el-row>
              <el-col :span="24">
                <div class="joinus-button-wrapper">
                  <a href="/career" target="_blank">
                    <img class="joinus-button" src="@/assets/img/mobile/joinus-button.svg" />
                  </a>
                  <img class="qr-icon" src="@/assets/img/mobile/qr-icon.svg" />
                  <div class="wechat">Wechat：cz2128delta</div>
                  <div class="email">E-mail：hr@starman.ltd</div>
                </div>
              </el-col>
            </el-row>
          </u-animate>
        </div>
      </div>
      <u-animate
          name="fadeIn"
          delay="0s"
          duration="1s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
      >
        <img class="star-icon1" src="@/assets/img/mobile/star-icon2.svg" />
      </u-animate>
    </div>
  </div>
</template>

<script>
import bg from '@/assets/img/mobile/joinus-bg.jpg'
export default {
  name: 'ARHero',
  data() {
    return {
      swiper: null,
      bg
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.page-bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  background-color: #000000;
}

.page-content {
  position: relative;
  padding-top: 48px;
  min-height: calc(100vh - 48px);
  height: 100%;
  overflow: hidden;

  .max-container {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .ar-title {
    margin-top: 3em;

    img {
      width: 100%;
    }

    h2 {
      margin: 1em 0;
      font-weight: 900;
      font-size: 4.5em;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: left;

      b {
        position: relative;
        display: block;
        text-align: right;
        margin-right: 1.2em;
        font-size: .5em;
      }
    }
  }

  .joinus-wrapper {
    position: absolute;
    width: 100%;
    bottom: 2em;

    .title {
      margin-bottom: 1em;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 122%;
      color: #FFFFFF;
    }

    .joinus-list-title {
      margin-bottom: 6px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      color: #ffffff;
      opacity: .6;
    }

    .joinus-list-item {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #FFFFFF;
    }

    .joinus-button-wrapper {
      text-align: center;

      .joinus-button {
        margin-top: 18px;
        width: 120px;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
      }

      .qr-icon {
        margin: 3em auto 1em auto;
        display: block;
        width: 64px;
        height: 64px;
      }

      .wechat,
      .email {
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
      }
    }
  }

  .starman-ar-icon {
    float: right;
    margin-top: 2em;
    margin-left: 1em;
    height: 64px;
  }

  .starman-ar-list {
    margin-top: 3em;
    width: 100%;
  }

  .starman-title-bg {
    position: absolute;
    text-align: left;
    top: 10%;
    left: -20px;
    font-style: normal;
    font-weight: 900;
    font-size: 6.25em;
    font-family: 'Gantari';
    line-height: .9em;
    letter-spacing: 0.205em;
    opacity: .6;
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-stroke: 1px #FFFFFF;
    user-select: none;
    z-index: 0;
  }

  .threebody-title-bg {
    position: absolute;
    text-align: left;
    bottom: 18%;
    left: -40px;
    font-style: normal;
    font-weight: 900;
    font-size: 8.25em;
    font-family: 'Gantari';
    line-height: .9em;
    letter-spacing: 0.205em;
    opacity: 1;
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-stroke: .5px #FFFFFF;
    user-select: none;
    z-index: -1;
  }

  .star-icon1 {
    position: absolute;
    bottom: 24%;
    right: 0;
    width: 164px;
  }

  .logo-icon {
    position: absolute;
    bottom: 4em;
    right: 2em;
    width: 60px;
    height: 60px;
  }

  .city-bg {
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    height: calc(100vh + 40px);
    z-index: -1;
  }

}
</style>