<template>
  <div>
    <div class="swiper-container swiper-container-ar">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <transition name="fade">
            <div class="page-bg" :style="{backgroundImage: `url(${bg3})`}" v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 0"></div>
          </transition>
          <div class="page-content">
            <div class="max-container">
              <el-row>
                <el-col :span="24">
                  <transition name="fade-left-50">
                    <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 0">
                      <h1>增强现实+
                        <span>STAR<br/>MAN</span>
                        <b>星徒科技</b>
                      </h1>
                    </div>
                  </transition>
                </el-col>
              </el-row>
            </div>
            <div class="ar-tags">
              <transition name="fade-left-50">
                <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 0">
                  <img src="@/assets/img/ar-tags.svg" />
                </div>
              </transition>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <transition name="fade">
            <div class="page-bg" :style="{backgroundImage: `url(${bg4})`}" v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 1"></div>
          </transition>
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="8">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 1">
                      <div class="project">
                        <div class="project-about">
                          <h2>AR市场前景</h2>
                          <div class="project-info">
                            AR、VR是元宇宙构建基础中最重要的一环，目前苹果、微软、Meta（前Facebook）等公司都跻身AR、XR的硬件设备开发，而目前业内较为缺乏具体的应用和对应的生态。
                          </div>
                          <div class="project-info">
                            如今，AR、VR等沉浸式技术正在快速发展，一定程度上改变了消费者、企业与数字世界的互动方式。用户期望更大程度上从[2D]转移到沉浸感更强的3D，从3D获得新的体验，包括商业、体验店、机器人、虚拟助理、区域规划、监控等，人们从只使用语言功能升级到包含视觉在内的全方位体验。
                          </div>
                          <div class="project-info">
                            与此同时，许多奢侈品、运动品牌也在尝试此等新兴内容展示形式，因AR\VR可以为产品带来更为直观的展示效果，为传统商品赋能，为文创、娱乐市场带来新的活力。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="16">
                </el-col>
              </el-row>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 2">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="title-wrapper">
                              <div class="title">应用场景</div>
                              <div class="subtitle">Application scenario</div>
                            </div>
                          </div>
                          <h2>AR+娱乐</h2>
                          <div class="project-info">
                            AR本身具有的娱乐性和其他娱乐载体结合能带来质的改变，打破第四面墙，让用户不再是旁观者而是真正参与其中
                          </div>
                          <div class="project-info">
                            小时候总想玩具可以动起来，将AR与玩具结合，圆儿时的梦；
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <img class="project-cover" src="@/assets/img/ar-cover1.jpg"  />
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 3">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="title-wrapper">
                              <div class="title">应用场景</div>
                              <div class="subtitle">Application scenario</div>
                            </div>
                          </div>
                          <h2>AR+影视</h2>
                          <div class="project-info">
                            将AR与影视直播结合,让观众参与到影视互动中，增添独特的体验，让观众在现实中见到梦寐以求的人物；
                          </div>
                          <div class="project-info">
                            初音未来的全息演唱会，采用的是“德国Sax 3D公司的3D全息透明屏”播放3D影像实现的，将初音未来活灵活现地搬到了现实中，深受粉丝喜爱，运用于虚拟主播等各种3D角色上有非常好的视觉冲击力。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <img class="project-cover" src="@/assets/img/ar-cover2.jpg"  />
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 4">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="title-wrapper">
                              <div class="title">应用场景</div>
                              <div class="subtitle">Application scenario</div>
                            </div>
                          </div>
                          <h2>AR+游戏</h2>
                          <div class="project-info">
                            将AR与游戏结合，把游戏融合到现实世界中，增添全新的游戏玩法和机制，带来全然不同的乐趣。
                          </div>
                          <div class="project-info">
                            PokemonGO把宠物小精灵复原到了现实中，与现实地理信息相结合，到特定地点去战斗和捕捉，非常方便与线下商家、展会进行联动，谁不想拥有一只可爱的宠物小精灵呢？PokemonGo游戏上线之前，任天堂市值不足200亿美元，上线之后，任天堂一周时间涨逾80%，不到一个月时间日活跃用户迅速增至4500万。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <img class="project-cover" src="@/assets/img/ar-cover3.jpg"  />
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 5">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="title-wrapper">
                              <div class="title">应用场景</div>
                              <div class="subtitle">Application scenario</div>
                            </div>
                          </div>
                          <h2>AR+出行</h2>
                          <div class="project-info">
                            出门旅游、打卡总是缺少对旅游地的了解和新奇的游玩体验？AR让你的出行具有更多趣味性和意义，针对特定景区的AR导览和将特效加入空间的AR打卡。
                          </div>
                          <div class="project-info">
                            2022年冬奥会《构建一朵雪花》节目是开幕式当中最令人印象深刻的场景之一，除去高达60米，宽达20米的巨型LED屏、超强实时动捕互动是关键外，AR也在当中发挥了作用。96块写着各国名字汇聚在一起的的雪花为虚拟雪花正是通过AR技术实现。同时奥运会场馆使用了AR技术为来宾提供导航导览和介绍服务。
                            将AR与游戏结合，把游戏融合到现实世界中，增添全新的游戏玩法和机制，带来全然不同的乐趣。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <img class="project-cover" src="@/assets/img/ar-cover4.jpg"  />
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 6">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="title-wrapper">
                              <div class="title">应用场景</div>
                              <div class="subtitle">Application scenario</div>
                            </div>
                          </div>
                          <h2>AR+营销</h2>
                          <div class="project-info">
                            AR本身的视觉冲击力非常适合作为一种营销方案，将AR与包装、海报等宣传物料结合，让客户体验到产品的特色或卖点，同时让客户更愿意扫描二维码参与到线上营销活动中
                          </div>
                          <div class="project-info">
                            可口可乐2016年开启奥运AR游戏，扫描可口可乐与冰露logo即刻出发游戏，赢取限量奥运纪念勋章，引导玩家进入可口可乐天猫旗舰店，游戏发布当天，可口可乐天猫旗舰店访问量环比增长1500%，互动过程心音有趣，转化率环比提升13倍。2022年可口可乐公司推出“星漫步”口味，宣布将于欧美流行歌手Ava Max合作，举办一场线上AR音乐会。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <img class="project-cover" src="@/assets/img/ar-cover5.jpg"  />
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 6 && swiper!=null && swiper.realIndex === 7">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="title-wrapper">
                              <div class="title">应用场景</div>
                              <div class="subtitle">Application scenario</div>
                            </div>
                          </div>
                          <h2>AR+教育</h2>
                          <div class="project-info">
                            AR提供了更直观更生动的用户体验，将一些抽象的概念从图片、视频进化为可以真正互动的模型，让学习过程更具趣味性，同时让一些需要操作的学习、实验更加方便和安全。
                          </div>
                          <div class="project-info">
                            AR教育从心理沉浸、感官沉浸、叙事和象征性沉浸、动作沉浸提升课程质量和体验，不管是学龄教育还是成人教育都能体现出独特的优势
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <img class="project-cover" src="@/assets/img/ar-cover6.jpg"  />
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="next-icon">
      <i class="el-icon-arrow-down" @click="$parent.navTo(8)" />
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import "swiper/dist/css/swiper.min.css";
import bg3 from '@/assets/img/ar-bg1.jpg'
import bg4 from '@/assets/img/ar-bg2.jpg'
import bg5 from '@/assets/img/threebody-bg5.jpg'
export default {
  name: "HeroPage",
  data() {
    return {
      swiper: null,
      bg3,
      bg4,
      bg5
    };
  },
  mounted() {
    this.myswiper()
  },
  methods: {
    myswiper: function() {
      let that = this
      this.swiper = new Swiper('.swiper-container-ar', {
        mousewheel: true,
        on:{
          slideNextTransitionEnd: function(){
            if (this.activeIndex === 7) {
              that.$parent.isNextScroll = true
            }
            that.$parent.isPrevScroll = false
          },
          slidePrevTransitionEnd: function(){
            if (this.activeIndex === 0) {
              that.$parent.isPrevScroll = true
            }
            that.$parent.isNextScroll = false
          }
        },
      });
    }
  },
};
</script>
<style scoped lang="scss">
  .swiper-container {
    height: 100vh;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .next-icon {
    position: absolute;
    margin: 0 auto;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    color: #FFFFFF;
    font-size: 23px;
    cursor: pointer;
    transition: all linear .1s;
    z-index: 2;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .time-text {
    position: absolute;
    right: 0;
    top: calc((100vh - 30px) / 2);
    bottom: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    letter-spacing: 0.175em;
    color: #FBFBFB;
    transform: rotate(-90deg);
    z-index: 2;
  }

  .page-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
    background-color: #000000;
  }

  .page-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .team-boss-bg {
      position: absolute;
      text-align: right;
      top: 15%;
      right: 20px;
      font-style: normal;
      font-weight: 900;
      font-size: 12.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      opacity: 0.2;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: 3px #397FE8;
      user-select: none;
      z-index: -1;
    }

    .team-boss-bg1 {
      position: absolute;
      text-align: right;
      top: 0;
      left: 20px;
      font-style: normal;
      font-weight: 900;
      font-size: 12.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: .5px #397FE8;
      user-select: none;
      z-index: -1;
    }

    .logo-smoke {
      position: absolute;
      width: 110px;
      bottom: 30px;
      left: 80px;
    }

    .logo-icon {
      position: absolute;
      width: 80px;
      bottom: 80px;
      right: 80px;
    }

    h1 {
      margin: 0;
      padding-left: 1em;
      font-weight: 900;
      font-size: 8em;
      line-height: 198px;
      letter-spacing: 0.055em;
      color: #FFFFFF;

      span {
        position: absolute;
        left: 0;
        top: -25%;
        font-style: normal;
        font-weight: 900;
        font-size: 1.25em;
        line-height: 1em;
        letter-spacing: 0.2em;
        background: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-text-stroke: 0.6px #397FE8;
        z-index: -1;
      }

      b {
        position: absolute;
        padding-left: 8em;
        left: 0;
        font-size: .25em;
        bottom: -5em;
      }
    }



    .project {
      display: flex;
      height: 100%;
      align-items: center;

      &.project-content-align-right {
        height: 100%;
        width: 100%;
        justify-content: right;
        align-items: flex-end;
      }
      .project-about {
        h2 {
          font-size: 25px;
          margin: 5px 0;
        }

        .project-about-title {
          display: flex;
          align-items: flex-end;
          .number {
            font-family: 'Dynalight';
            font-style: normal;
            font-weight: 400;
            font-size: 14em;
            line-height: .6em;
          }
          .title-wrapper {
            margin-bottom: 10px;

            .title {
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 46px;
              letter-spacing: 0.165em;
            }

            .subtitle {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.165em;
              text-transform: uppercase;
            }
          }
        }

        .project-info {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 220%;
          text-align: justify;
          letter-spacing: 0.175em;
          margin-top: 2em;
        }
      }
    }

    .ar-tags {
      position: absolute;
      right: 11em;
      bottom: 15em;
      width: 25%;
      z-index: -1;

      img {
        width: 100%;
      }
    }

    .project-cover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
    }

    .threebody-cover2 {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 80%;
      z-index: -1;
    }

    .water {
      height: 190px;
      margin: 0 auto;
      display: block;
    }
  }
</style>