<template>
  <div>
    <div class="swiper-container swiper-container-threebody">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <transition name="fade">
            <div class="page-bg" :style="{backgroundImage: `url(${bg3})`}" v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 0"></div>
          </transition>
          <div class="page-content">
            <div class="max-container">
              <el-row>
                <el-col :span="24">
                  <transition name="fade-up-50">
                    <div v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 0">
                      <h1>三体IP项目规划
                        <span>STAR<br/>MAN</span>
                        <b>星徒科技</b>
                      </h1>
                    </div>
                  </transition>
                </el-col>
              </el-row>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <transition name="fade">
            <div class="page-bg" :style="{backgroundImage: `url(${bg4})`}" v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 1"></div>
          </transition>
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 1">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="number">1</div>
                            <div class="title-wrapper">
                              <div class="title">项目概述</div>
                              <div class="subtitle">PROJECT SUMMARY</div>
                            </div>
                          </div>
                          <div class="project-info">
                            本项目是以《三体世界观》内容为基础制作的AR世界观、设定集，用户可通过各种电子设备扫描《三体》、《三体世界观》书面内容以观看对应模型、场景。
                            <br/>
                            <br/>
                            <b>DEMO</b>
                          </div>
                          <div class="project-info">
                            以红岸基地为原型制作的场景模型，可进行观看场景模型，点击模型相应位置查看细节介绍，场景内容互动，查看场景背景故事等操作
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 2">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-about-title ">
                            <div class="number">2</div>
                            <div class="title-wrapper">
                              <div class="title">项目内容</div>
                              <div class="subtitle">PROJECT CONTENT</div>
                            </div>
                          </div>
                          <div class="project-info">
                            章节划分
                            整体划分根据《三体》、《三体世界观》分为三个部分，目前制作第一部，其中第一部包括25个场景和封面、封底一共27个场景，分为5个章节
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
              </el-row>
            </div>
            <div class="threebody-cover1">
              <transition name="fade-left-50">
                <div v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 2">
                  <img src="@/assets/img/threebody-bg3.jpg" />
                </div>
              </transition>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-content">
            <div class="max-container">
              <el-row type="flex" justify="space-between">
                <el-col :span="7">
                  <transition name="fade-right-50">
                    <div v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 3">
                      <div class="project">
                        <div class="project-about">
                          <h2>模型内容</h2>
                          <div class="project-info">
                            利用AR技术，将《三体》小说文字中的场景提取到现实中来。用户可以通过XXX（软件名）来操作模型，体验文字小说无法实现的沉浸式互动，让用户有种身处其中的临场感。
                          </div>
                          <div class="project-info">
                            例如，用户可以通过操作控制面板体验小说中叶文洁向外太空发射信号的剧情桥段。模型内容主要还原了《三体》小说内以宏观叙事和奇思妙想所带来的大型场景，包括其中的建筑、人物和相应动画效果。
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </el-col>
                <el-col :span="17">
                </el-col>
                <el-col :span="7">
                  <div class="project project-content-align-right">
                    <div class="project-about">
                      <div class="project-about-title">
                        <div class="number">2</div>
                        <div class="title-wrapper">
                          <div class="title">项目内容</div>
                          <div class="subtitle">PROJECT CONTENT</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="threebody-cover2">
              <transition name="fade-left-50">
                <div v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 3">
                  <img src="@/assets/img/threebody-bg4.jpg" />
                </div>
              </transition>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
        <div class="swiper-slide">
          <div class="page-bg" :style="{backgroundImage: `url(${bg5})`}"></div>
          <div class="page-content">
            <div class="max-container">
              <el-row :gutter="80">
                <el-col :span="24">
                  <div class="water">
                    <transition name="fade-down-50">
                      <div v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 4">
                        <img src="@/assets/img/water.png" />
                      </div>
                    </transition>
                  </div>
                </el-col>
              </el-row>
              <transition name="fade-up-50">
                <div v-if="$parent.index === 5 && swiper!=null && swiper.realIndex === 4">
                  <el-row :gutter="80">
                    <el-col :span="8">
                      <div class="project">
                        <div class="project-about">
                          <h2>文字内容</h2>
                          <div class="project-info">
                            第一章节为三体游戏（上），包含了原著中汪淼以“海人”的身份前两次登录三体游戏所遇见的场景。这个章节主要以汪淼的视角通过三体游戏认识到三体人所处的乱纪元以及他们脱水的能力，从而引出纣王需要人推演预测恒纪元与乱纪元的规律。
                          </div>
                          <div class="project-info">
                            第二章节为三体游戏（中），包含了汪淼以“哥白尼”的身份第三以及第四次登录三体游戏的场景。汪淼揭露了宇宙的基本结构：即之前出现的飞星以及太阳为同一星体，从而推动三体游戏进入第二阶段，第四次则见到了人列计算机。
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-info">
                            第三章节为三体游戏（下），包含了汪淼第五次以及第六次进入三体游戏。三体星在汪淼错过的时间内经历了大撕裂，三体游戏的目标从预测恒纪元与乱纪元的规律转变为星际移民。汪淼与其他玩家最后见证了三体舰队的出征，随后三体游戏结束。

                          </div>
                          <div class="project-info">
                            第四章节以地球上发生的故事为主，包括了魏成向汪淼一行演算三体问题的剧情，红岸基地当年的故事（DEMO演示内容）以及叶文洁重回红岸基地和审判日号成为第二红岸基地后被古筝行动破坏。
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="project">
                        <div class="project-about">
                          <div class="project-info">
                            第五章节则将视角转移到了三体星，主要包括了当年接收到红岸基地发来信号的1379监听站和智子三次展开。解释了宇宙中其他文明寻找信号源的原理以及初步展示宇宙中各个文明之间的看法和道德观，为后面引出黑暗森林法则铺垫。也通过展开质子展现了三体文明中高度发展的科技水平。
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </transition>
            </div>
            <img class="logo-smoke" src="@/assets/img/logo-smoke.png" />
            <img class="logo-icon" src="@/assets/img/logo-icon.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="next-icon">
      <i class="el-icon-arrow-down" @click="$parent.navTo(7)" />
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import "swiper/dist/css/swiper.min.css";
import bg3 from '@/assets/img/threebody-bg.jpg'
import bg4 from '@/assets/img/threebody-bg2.jpg'
import bg5 from '@/assets/img/threebody-bg5.jpg'
export default {
  name: "HeroPage",
  data() {
    return {
      swiper: null,
      bg3,
      bg4,
      bg5
    };
  },
  mounted() {
    this.myswiper()
  },
  methods: {
    myswiper: function() {
      let that = this
      this.swiper = new Swiper('.swiper-container-threebody', {
        mousewheel: true,
        on:{
          slideNextTransitionEnd: function(){
            if (this.activeIndex === 4) {
              that.$parent.isNextScroll = true
            }
            that.$parent.isPrevScroll = false
          },
          slidePrevTransitionEnd: function(){
            if (this.activeIndex === 0) {
              that.$parent.isPrevScroll = true
            }
            that.$parent.isNextScroll = false
          }
        },
      });
    }
  },
};
</script>
<style scoped lang="scss">

  .swiper-container {
    height: 100vh;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .next-icon {
    position: absolute;
    margin: 0 auto;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    color: #FFFFFF;
    font-size: 23px;
    cursor: pointer;
    transition: all linear .1s;
    z-index: 2;

    &:hover {
      transform: translateY(-3px);
    }
  }

  .time-text {
    position: absolute;
    right: 0;
    top: calc((100vh - 30px) / 2);
    bottom: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    height: 30px;
    letter-spacing: 0.175em;
    color: #FBFBFB;
    transform: rotate(-90deg);
    z-index: 2;
  }

  .page-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: cover;
    background-color: #000000;
  }

  .page-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .team-boss-bg {
      position: absolute;
      text-align: right;
      top: 15%;
      right: 20px;
      font-style: normal;
      font-weight: 900;
      font-size: 12.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      opacity: 0.2;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: 3px #397FE8;
      user-select: none;
      z-index: -1;
    }

    .team-boss-bg1 {
      position: absolute;
      text-align: right;
      top: 0;
      left: 20px;
      font-style: normal;
      font-weight: 900;
      font-size: 12.25em;
      font-family: 'Gantari';
      line-height: .9em;
      letter-spacing: 0.205em;
      background: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-stroke: .5px #397FE8;
      user-select: none;
      z-index: -1;
    }

    .logo-smoke {
      position: absolute;
      width: 110px;
      bottom: 30px;
      left: 80px;
    }

    .logo-icon {
      position: absolute;
      width: 80px;
      bottom: 80px;
      right: 80px;
    }

    h1 {
      margin: 0;
      padding-left: 1em;
      font-weight: 900;
      font-size: 8em;
      line-height: 198px;
      letter-spacing: 0.055em;
      color: #FFFFFF;

      span {
        position: absolute;
        left: 0;
        top: -25%;
        font-style: normal;
        font-weight: 900;
        font-size: 1.25em;
        line-height: 1em;
        letter-spacing: 0.2em;
        background: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-text-stroke: 0.6px #FBFBFB;
      }

      b {
        position: absolute;
        padding-left: 8em;
        left: 0;
        font-size: .25em;
        bottom: -5em;
      }
    }



    .project {
      display: flex;
      height: 100%;
      align-items: center;

      &.project-content-align-right {
        height: 100%;
        width: 100%;
        justify-content: right;
        align-items: flex-end;
      }
      .project-about {
        h2 {
          font-size: 25px;
          margin: 5px 0;
        }

        .project-about-title {
          display: flex;
          align-items: flex-end;
          .number {
            font-family: 'Dynalight';
            font-style: normal;
            font-weight: 400;
            font-size: 14em;
            line-height: .6em;
          }
          .title-wrapper {
            margin-bottom: 10px;

            .title {
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 46px;
              letter-spacing: 0.165em;
            }

            .subtitle {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.165em;
            }
          }
        }

        .project-info {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 220%;
          text-align: justify;
          letter-spacing: 0.175em;
          margin-top: 2em;
          text-indent: 2em;
        }
      }
    }

    .threebody-cover1 {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .threebody-cover2 {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 80%;
      z-index: -1;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .water {
      height: 190px;
      margin: 0 auto;
      display: flex;
      position: relative;
      justify-content: center;

      img {
        height: 100%;
      }
    }
  }
</style>