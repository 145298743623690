<template>
  <div>
    <div class="page-content">
      <div class="page-bg" :style="{backgroundImage: `url(${bg2})`}"></div>
      <div class="max-container">
        <u-animate-container>
          <u-animate
              name="fadeIn"
              delay="0s"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false"
          >
            <el-row>
              <el-col :span="24">
                <div class="about-us-title">
                  公司概述
                </div>
                <div class="about-us-content">
                  前身<br />
                  我司前身为MC团队，自14年主要与网易、斗鱼、优酷、腾讯、联合国等举办Minecraft、LEGO无限赛事，制作和协助过多部刘慈欣IP改编MC动画，与共青团等公益组织有过多次公益动画联动，有丰富的赛事活动、游戏开发、IP产品打造经验。
                  <div class="content-title">脚踏现实，直面未来</div>
                  我们一直保持创业热情和年轻，热爱猫猫，热爱生活和游戏，严谨而不严肃，专业而知变通，不为自己设置边界循规蹈矩。<br/>
                  我们看到不确定性背后的机遇，看到艰苦卓绝背后的成果，看到天马行空背后的力量——看到我们所看到的新未来，而我们将选择直面现实，承认现实，挑战现实并创造实现属于我们自己的现实。
                </div>
              </el-col>
            </el-row>
          </u-animate>
        </u-animate-container>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon-blue.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(3)" />
    </div>
  </div>
</template>

<script>
import bg2 from '@/assets/img/bg2.jpg'
export default {
  name: "AboutUsPage",
  data() {
    return {
      swiper: null,
      bg2
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.page-bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  background-color: #000000;

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.page-content {
  position: relative;
  padding-top: 48px;
  min-height: calc(100vh - 48px);
  height: 100%;
  overflow: hidden;

  .arrow-icon {
    position: absolute;
    display: block;
    bottom: 3em;
    left: 0;
    right: 0;
    height: 64px;
    margin: 0 auto;
    cursor: pointer;
  }

  .max-container {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .about-us-title {
    margin-top: 2em;
    margin-bottom: 2em;
    position: relative;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.165em;
    color: #FBFBFB;

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -11px;
      z-index: -1;
      content: 'Company overview';
      font-weight: 700;
      font-size: 11px;
      line-height: 28px;
      letter-spacing: 0;
      text-transform: uppercase;
      color: #397FE8;

    }
  }

  .about-us-content {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 200%;
    letter-spacing: 0.175em;

    .content-title {
      margin: 2em 0;
      font-size: 1.3em;
    }
  }

}


</style>