<template>
  <div>
    <div class="page-content">
      <u-animate
          name="fadeIn"
          delay="0s"
          duration="2s"
          :iteration="1"
          :offset="0"
          animateClass="animated"
          :begin="false"
      >
        <div class="page-bg" :style="{backgroundImage: `url(${bg})`}"></div>
      </u-animate>
      <div class="ar-title-bg">
        <u-animate
            name="slideInLeft"
            delay="0s"
            duration="1s"
            :iteration="1"
            :offset="0"
            animateClass="animated"
            :begin="false"
        >
          ARx游戏
        </u-animate>
      </div>
      <div class="max-container">
        <el-row type="flex" justify="center">
          <el-col :span="22">
            <u-animate
                name="slideInRight"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
                :begin="false"
            >
              <div class="project">
                <div class="project-about">
                  <div class="project-info">
                    <b><span>AR</span>+游戏</b>
                    将AR与游戏结合，把游戏融合到现实世界中，增添全新的游戏玩法和机制，带来全然不同的乐趣。<br/><br/>
                    PokemonGO把宠物小精灵复原到了现实中，与现实地理信息相结合，到特定地点去战斗和捕捉，非常方便与线下商家、展会进行联动.
                  </div>
                </div>
              </div>
            </u-animate>
          </el-col>
        </el-row>
      </div>
      <img class="arrow-icon" src="@/assets/img/mobile/arrow-icon.svg" @click="$parent.$parent.$refs.navbar.mobileNavTo(25)" />
    </div>
  </div>
</template>

<script>
import bg from '@/assets/img/mobile/ar-bg3.jpg'
export default {
  name: "ThreeBodyHero",
  data() {
    return {
      swiper: null,
      bg
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.page-bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  background-color: #000000;
}

.page-content {
  position: relative;
  padding-top: 48px;
  min-height: calc(100vh - 48px);
  height: 100%;
  overflow: hidden;

  .arrow-icon {
    position: absolute;
    display: block;
    bottom: 3em;
    left: 0;
    right: 0;
    height: 64px;
    margin: 0 auto;
    cursor: pointer;
  }

  .max-container {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .project {
    display: flex;
    height: 100%;
    align-items: center;

    .project-about {
      margin-top: 2em;
      .project-about-title {
        margin-top: 3em;
        display: flex;
        align-items: flex-end;

        .number {
          font-family: 'Dynalight';
          font-style: normal;
          font-weight: 400;
          font-size: 8.6em;
          line-height: .6em;
        }

        .title-wrapper {
          margin-left: 6px;
          margin-bottom: 6px;

          .title {
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0.165em;
          }

          .subtitle {
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
          }
        }
      }

      .project-info {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 180%;
        text-align: justify;
        letter-spacing: 0.175em;
        margin-top: 2em;

        b {
          margin: .5em 0;
          display: block;
          font-size: 1.4em;

          span {
            color: #397FE8;
          }
        }
      }
    }
  }

  .starman-ar-icon {
    margin-top: 2em;
    margin-left: 1em;
    height: 64px;
  }

  .ar-title-bg {
    position: absolute;
    text-align: left;
    top: 8%;
    left: -25%;
    right: -25%;
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 6.65em;
    line-height: .9em;
    letter-spacing: 0.205em;
    opacity: 1;
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-stroke: 1px #FFFFFF;
    opacity: .3;
    user-select: none;
    z-index: 0;
  }

  .threebody-title-bg {
    position: absolute;
    text-align: left;
    bottom: 18%;
    left: -40px;
    font-style: normal;
    font-weight: 900;
    font-size: 8.25em;
    font-family: 'Gantari';
    line-height: .9em;
    letter-spacing: 0.205em;
    opacity: 1;
    background: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-text-stroke: .5px #FFFFFF;
    user-select: none;
    z-index: -1;
  }

  .star-icon1 {
    position: absolute;
    bottom: 24%;
    right: 0;
    width: 164px;
  }

  .logo-icon {
    position: absolute;
    bottom: 4em;
    right: 2em;
    width: 60px;
    height: 60px;
  }

  .city-bg {
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    width: calc(100% + 40px);
    height: calc(100vh + 40px);
    z-index: -1;
  }

}
</style>