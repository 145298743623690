<template>
  <div>
    <PcHome v-if="!isMobile" />
    <MobileHome v-else />
  </div>
</template>

<script>
import PcHome from '@/views/Home/pc'
import MobileHome from '@/views/Home/mobile'
export default {
  name: 'HomeIndex',
  components: {
    PcHome,
    MobileHome,
  },
  data() {
    return {
      isMobile: false,
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleLayouts)
    this.handleLayouts()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleLayouts)
  },
  methods: {
    handleLayouts() {
      // 移动端的自适应切换
      const isMobile = document.body.getBoundingClientRect().width - 1 < 992
      if (this.isMobile !== isMobile) {
        this.isMobile = isMobile
      }
    }
  }
}
</script>

<style scoped>

</style>