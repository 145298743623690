<template>
  <div class="navbar">
    <div class="navbar-container">
      <img class="logo" :src="logo" v-if="!isMobile" />
      <img class="logo" :src="logoWhite" v-else />
      <div class="nav-list">
        <div class="nav-list-item" @click="navTo(1)" :class="{ 'is-active': curIndex == 1 }">
          首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页
        </div>
        <div class="nav-list-item" @click="navTo(2)" :class="{ 'is-active': curIndex == 2 }">
          关于公司
        </div>
        <div class="nav-list-item" @click="navTo(3)" :class="{ 'is-active': curIndex >= 3 && curIndex <= 5 }">
          项目介绍
        </div>
        <div class="nav-list-item" @click="navTo(6)" :class="{ 'is-active': curIndex == 6 }">
          增强现实
        </div>
        <div class="nav-list-item" @click="navTo(7)" :class="{ 'is-active': curIndex == 7 }">
          加入我们
        </div>
      </div>
      <div class="mobile-menu" @click="menuToggled = !menuToggled">
        <img class="menu-icon" src="@/assets/img/navbar_menu.svg" />
      </div>
    </div>
    <div class="mobile-sidebar" :class="menuToggled?'is-toggled':''">
      <div class="close-button">
        <img class="close-icon" src="@/assets/img/navbar_menu_close.svg" @click="menuToggled = !menuToggled" />
      </div>
      <div class="menu-list">
        <div class="menu-list-item" @click="mobileNavTo(1)" @click.stop="menuToggled = !menuToggled" :class="{ 'is-active': mobileIndex == 1 }">首页</div>
        <div class="menu-list-item" @click="mobileNavTo(2)" @click.stop="menuToggled = !menuToggled" :class="{ 'is-active': mobileIndex == 2 }">关于公司</div>
        <!-- <div class="menu-list-item" @click="mobileNavTo(3)" @click.stop="menuToggled = !menuToggled" :class="{ 'is-active': mobileIndex >= 3 && mobileIndex <= 7 }">团队介绍</div> -->
        <div class="menu-list-item" @click="mobileNavTo(3)" @click.stop="menuToggled = !menuToggled" :class="{ 'is-active': mobileIndex >= 3 && mobileIndex <= 15 }">项目介绍</div>
        <div class="menu-list-item" @click="mobileNavTo(16)" @click.stop="menuToggled = !menuToggled" :class="{ 'is-active': mobileIndex >= 16 && mobileIndex <= 22 }">增强现实</div>
        <div class="menu-list-item" @click="mobileNavTo(23)" @click.stop="menuToggled = !menuToggled" :class="{ 'is-active': mobileIndex == 23 }">加入我们</div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/logo.svg'
import logoWhite from '@/assets/img/logo-white.svg'
export default {
  name: 'NavBar',
  props: {
    navTo: {
      type: Function,
    }
  },
  data() {
    return {
      logo,
      logoWhite,
      menuToggled: false,
      curIndex: 0,
      mobileIndex: 0,
      isMobile: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.curIndex = 1
    this.mobileIndex = 1
  },
  beforeMount() {
    window.addEventListener('resize', this.handleLayouts)
    this.handleLayouts()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleLayouts)
  },
  methods: {
    handleLayouts() {
      // 移动端的自适应切换
      const isMobile = document.body.getBoundingClientRect().width - 1 < 992
      if (this.isMobile !== isMobile) {
        this.isMobile = isMobile
      }
    },
    mobileNavTo(page) {
      this.mobileIndex = page
      window.scrollTo(0, this.$parent.$refs[`page${page}`].$el.offsetTop)
    },
    handleScroll() {
      for(let i = 0; i < this.$parent.pages; i++) {
        if (i + 1 < this.$parent.pages) {
          if (this.$parent.$refs[`page${i + 1}`].$el.offsetTop <= window.scrollY && this.$parent.$refs[`page${i + 2}`].$el.offsetTop > window.scrollY) {
            this.mobileIndex = i + 1
            break;
          }
        } else {
          this.mobileIndex = 28
        }
      }
    }
  }
}
</script>

<style lang="scss">
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 12px 60px;
  background: rgba(0,0,0,0);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);

  .navbar-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    height: 42px;
  }

  .nav-list {
    display: flex;
    align-items: center;

    .nav-list-item {
      position: relative;
      padding: 0 15px;
      color: #ffffff;
      cursor: pointer;
      font-weight: 400;
      font-size: 15px;
      line-height: 42px;
      user-select: none;

      &.is-active {
        font-weight: 900;
        color: #397FE8;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        width: calc(100% - 40px);
        margin: 0 20px;
        height: 4px;
        background: #397FE8;
        border-radius: 8px;
        opacity: 0;
        transition: opacity ease-in-out .3s;
      }

      &.is-active::after {
        opacity: 1;
      }
    }
  }

  .mobile-menu {
    display: none;
  }

  .mobile-sidebar {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    padding: 14px 0;
    height: calc(100vh - 28px);
    background: #FFFFFF;
    transform: translate3d(calc(100% + 10px),0,0);
    transition: all;
    transition-duration: .3s;

    &.is-toggled {
      transform: translate3d(calc(100% - 200px),0,0);
    }

    .close-button {
      text-align: right;

      .close-icon {
        margin-right: 20px;
        width: 22px;
        height: 22px;
      }
    }

    .menu-list {
      margin-top: 40px;

      .menu-list-item {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.85em;
        text-align: center;
        padding: 22px 0;
        border-bottom: 1px solid #eeeeee;
        color: #333333;

        &.is-active {
          color: #397FE8;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    padding: 8px 20px;

    .logo {
      height: 32px;
    }

    .mobile-menu {
      display: flex;
      align-items: center;
      .menu-icon {
        width: 22px;
        height: 22px;
      }
    }

    .nav-list {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 576px) {

}
</style>
